import React, { useEffect, useRef, useState } from 'react'
import './HealthConditionsStyles.scss'
import { ReactComponent as BackIcon } from '../../assests/icons/Back.svg'
import insurance_icon from '../../assests/images/isnurance.svg'
import { CircularProgress, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { getInsuranceApi } from '../../services/PatientHealth'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useNotification } from '../Appointments/MaterialUi/Notification'
const ELEMENT = 10
function Main() {
    const history = useHistory()
    const notification = useNotification()
    const [insuranceData, setInsuranceData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [bundleId, setBundleId] = useState('')
    const [page, setPage] = useState(1)
    const goToPage = () => history.goBack()
    const loggedinPatiendata = useSelector((state) => state?.login?.userData)
    const patient_id = useRef(loggedinPatiendata?.id)

    const flattendata = (data) => {
        if (!data || data.length === 0) return []

        return data.map((item) => {
            return {
                status: item?.status,
                type: item?.type,
                id: item?.id,
                patient_id: item?.patient_id,
                effective_date: item?.insurance?.policy_effective,
                end_date: item?.insurance?.policy_end,
                policy_no: item?.insurance?.policy_no,
                health_plan: item?.insurance?.plan?.display                
            }
        })
    }

    const getInsuranceData = () => {
        setLoading(true)
        const payload = {
            page,
            limit: ELEMENT,
            bundleId,
            patient_id: +patient_id?.current,
        }
        getInsuranceApi(payload)
            ?.then((res) => {
                const responseData = res?.data?.data
                setBundleId(res?.data?.bundle_id || '')
                setInsuranceData(flattendata(responseData))
            })
            .catch(() => {
                notification('Something went wrong!', 'error')
            })
            .finally(() => setLoading(false))
    }
    useEffect(() => {
        getInsuranceData()
    }, [])

    return (
        <>
            <div className="health_condition_main_container">
                <div className="health_condition_sub_container">
                    <div className="health_condition_back_container" onClick={goToPage}>
                        <BackIcon />
                        <Typography>Back to health record</Typography>
                    </div>
                    <div className="health_condition_body">
                        <div className="health_condition_body_header">
                            <img
                                src={insurance_icon}
                                alt="medication_allergies"
                                height={20}
                                width={20}
                            />
                            <Typography>Insurance</Typography>
                        </div>
                        <div className="health_conditions_body_contents">
                            <div className="health_conditions_tab_contents">
                                <div className="health_conditions_tab_content_third_row">
                                    <span className="health_conditions_tab_content_count">
                                        {loading ? (
                                            <CircularProgress
                                                style={{
                                                    color: '#667689',
                                                    height: 15,
                                                    width: 15,
                                                    marginRight: 3,
                                                }}
                                            />
                                        ) : (
                                            <Typography>{insuranceData?.length}</Typography>
                                        )}
                                        <Typography>insurance records</Typography>
                                    </span>
                                </div>

                                {loading ? (
                                    <div className="health_condition_cards_loader">
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <div className="health_conditions_card_container">
                                        {insuranceData?.length > 0 &&
                                            insuranceData?.map((data) => {
                                                return (
                                                    <div
                                                        key={data?.id}
                                                        className="health_conditions_cards">
                                                        <div className="health_conditions_cards_contents">
                                                            <Typography
                                                                style={{
                                                                    color: '#303E4E',
                                                                    fontSize: 17,
                                                                    fontWeight: 500,
                                                                }}>
                                                                    {data?.health_plan || 'Health Plan'}
                                                                    -{' '}
                                                                <span
                                                                    style={{
                                                                        color: '#667689',
                                                                        fontFamily: 'Rubik',
                                                                        fontSize: '15px',
                                                                        fontStyle: 'normal',
                                                                        fontWeight: '400',
                                                                        lineHeight: '140%',
                                                                    }}>
                                                                    {data?.type}
                                                                </span>
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    color: '#667689',
                                                                    fontSize: 14,
                                                                    fontWeight: 400,
                                                                }}>
                                                                Effective Dates
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    color: '#303E4E',
                                                                    fontSize: 16,
                                                                    fontWeight: 400,
                                                                }}>
                                                                {data?.effective_date
                                                                    ? moment(
                                                                          data?.effective_date,
                                                                      ).format('MM/DD/YYYY')
                                                                    : 'N/A'}{' '}
                                                                -{' '}
                                                                {data?.end_date
                                                                    ? moment(data?.end_date).format(
                                                                          'MM/DD/YYYY',
                                                                      )
                                                                    : 'N/A'}
                                                            </Typography>
                                                        </div>
                                                        <div className="health_conditions_card_type">
                                                            <span
                                                                style={{
                                                                    color:
                                                                        data.status === 'active'
                                                                            ? '#14AE5C'
                                                                            : '#FFAC0C',
                                                                    background:
                                                                        data.status === 'active'
                                                                            ? '#EBFFF3'
                                                                            : '#FEEDC6',
                                                                }}>
                                                                {data?.status}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Main
