import React, { useLayoutEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
    Box,
    Divider,
    Grid,
    TextField,
    Dialog,
    Typography,
    CircularProgress,
} from '@material-ui/core'
import { Button } from '@mui/material'
import * as Yup from 'yup'
import MomentUtils from '@date-io/moment'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useFormik } from 'formik'
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import CheckboxOption from '../../../ClinicalNotes/reusable/CheckboxOptions'

import { me, updateMe } from '../../../../services/Login/login'
import moment from 'moment'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import { stateList } from '../../../../containers/AppointmentBooking/constant'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import SharedContactModal from '../../../HealthReport/Module/sharedContactModal/SharedContactModal'
import UncheckSharedModal from '../../../HealthReport/Module/sharedContactModal/UncheckSharedModal'
import { Spinner } from '@react-pdf-viewer/core'
import { Image } from '@material-ui/icons'
import { formatPhoneNumber } from '../../../../utilities'
import { useDispatch } from 'react-redux'
import { setPatientDataAfterChange } from '../../../../store/actions/login'
import { is } from 'date-fns/locale'
export const DemoGraphModal = ({ isOpenModal, setSteps, steps, handleNextStep, requireTranslator }) => {
    const dispatch = useDispatch()
    const useStyles = makeStyles((theme) =>
        createStyles({
            root: {
                '& .MuiPaper-root': {
                    height: '710px;',
                    position: 'relative',
                },
                '& .MuiDialogContent-root': {
                    overflow: 'auto',
                },
                '& .MuiDialog-paperWidthSm': {
                    maxWidth: '780px',
                },
            },
            modalBody: {
                // width: '719px',
                // height: '814px',
                height: '100%',
                background: 'white',
                borderColor: '#FFF',
                // paddingLeft: '26px',
                // paddingRight: '34px',
                borderRadius: '8px',
                overflow: 'hidden auto',
                '&:focus': {
                    outline: 'none', // Remove the focus outline
                },
            },
            blueToBeEditLine: {
                color: '#5571C6',
                fontFamily: 'Rubik',
                fontSize: '15',
                fontWeight: 400,
                cursor: 'pointer',
            },
            errorLine: {
                color: 'red',
                fontSize: '13px',
            },
            clearOutlinedIcon: {
                cursor: 'pointer',
                fontSize: 'small',
                color: '#667689',
                border: '1px solid #A3ABB5',
            },
            checkOutlinedIcon: {
                cursor: 'pointer',
                fontSize: 'small',
                color: '#667689',
                border: '1px solid #A3ABB5',
            },
            hiddenBlueLine: {
                color: '#667689',
                fontSize: '15',
                fontWeight: 400,
                cursor: 'pointer',
            },
            tickPressClass: {
                color: 'white',
                border: '1px solid #e3e1e1',
                height: '13px',
                width: '11px',
                borderRadius: '2px',
                marginTop: '11px',
                cursor: 'pointer',
            },
            isEditMode: {
                boxSizing: 'border-box',
                outline: 'none',
                border: '2px solid #E2E3E5',
                fontFamily: 'Rubik',
                borderRadius: '5px',
                padding: '5px',
                fontSize: '14px',
                backgroundColor: '#fff',
                color: '#303E4E',
                marginTop: '8px',
            },
            label2: {
                color: '#303E4E',
                fontFamily: 'Rubik',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '140%',
            },
            labelStyle: {
                color: '#303E4E',
                fontFamily: 'Rubik',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '140%' /* 22.4px */,
                marginBottom: '10px',
            },

            typoGraphy: {
                color: '#303E4E',
                fontFamily: 'Rubik',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '140%',
            },
            typoGraphyInputVal: {
                color: '#303E4E',
                fontFamily: 'Rubik',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '16px',
                // marginTop: '10px',
            },
            gripDisplay: {
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '0px 10px',
            },
            select: {
                '& .MuiTypography-root': {
                    color: '#303E4E !important',
                },
            },
        }),
    )
    const [userData, setUserData] = useState(null)
    const classes = useStyles()
    const [isOpenModalContactUncheck, setisOpenModalContactUncheck] = useState(false)
    const [isOpenModalContact, setisOpenModalContact] = useState(false)
    const [isOpenModalEmailContact, setisOpenModalEmailContact] = useState(false)
    const [isOpenModalEmailContactUncheck, setisOpenModalEmailContactUncheck] = useState(false)
    const [isOpenModalContactSecUncheck, setisOpenModalContactSecUncheck] = useState(false)
    const [isOpenModalContactSec, setisOpenModalContactSec] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isTextFiledOpen, setIsTextFiledOpen] = useState({
        isNameVisible: false,
        isSec_Phone_visible: false,
        is_Dob_visible: false,
    })
    const [EditMode, setEditMode] = useState({
        name: false,
        pref_name: false,
        phone_number: false,
        secondary_phone_number: false,
        address: false,
    })
    const validationSchema = Yup.object().shape({
        phone_number: Yup.string().required('Phone number is required'),
        address1: Yup.string().required('Address Line 1 is required'),
        city: Yup.string().required('City  is required'),
        zip: Yup.string().required('Zip is required'),
        dob: Yup.string().required('DOB is required'),
        state: Yup.string().required('State is required'),
        first_name: Yup.string().required('First Name is required'),
        last_name: Yup.string().required('Last Name is required'),
    })
    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: {
            shared_phone: userData?.shared_phone || false,
            first_name: userData?.first_name || '',
            last_name: userData?.last_name || '',
            preferred_name: userData?.preferred_name || '',
            phone_number: userData?.phone_number || '',
            shared_secondary_phone: userData?.shared_secondary_phone || '',
            secondary_phone_number: userData?.secondary_phone_number || '',
            email: userData?.email || '',
            address1: userData?.address1 || '',
            address2: userData?.address2 || '',
            zip: userData?.zip || '',
            state: userData?.state || '',
            city: userData?.city || '',
            dob: userData?.dob || '',
            shared_email: userData?.shared_email || false,
        },
        onSubmit: formSubmit,
    })
    async function formSubmit(payload) {
        if (!formik?.values?.secondary_phone_number) {
            payload.shared_secondary_phone = false
        }
        const payLoadData = {
            ...userData,
            ...payload,
            require_translator: requireTranslator
        }

        try {
            setIsLoading(true)
            const data = await updateMe(payLoadData)
            dispatch(
                setPatientDataAfterChange({
                    phone_number: payLoadData?.phone_number,
                    first_name: payLoadData?.first_name,
                    last_name: payLoadData?.last_name,
                }),
            )
            localStorage.setItem('name', payLoadData.first_name + ' ' + payLoadData.last_name)
            if (data?.status === 200) {
                handleNextStep()
            } else {
                setSteps(5.5)
            }
        } catch (e) {
            //
        } finally {
            setIsLoading(false)
        }
    }

    const AddIcon = () => (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none">
                <path
                    d="M6.5 0C2.91597 0 0 2.91597 0 6.5C0 10.084 2.91597 13 6.5 13C10.084 13 13 10.084 13 6.5C13 2.91597 10.084 0 6.5 0ZM6.5 12.2778C3.31423 12.2778 0.722222 9.68577 0.722222 6.5C0.722222 3.31423 3.31423 0.722222 6.5 0.722222C9.68577 0.722222 12.2778 3.31423 12.2778 6.5C12.2778 9.68577 9.68577 12.2778 6.5 12.2778Z"
                    fill="#5571C6"
                />
                <path
                    d="M8.66661 6.1389H6.86106V4.33334C6.86106 4.13384 6.69944 3.97223 6.49995 3.97223C6.30045 3.97223 6.13883 4.13384 6.13883 4.33334V6.1389H4.33328C4.13378 6.1389 3.97217 6.30051 3.97217 6.50001C3.97217 6.69951 4.13378 6.86112 4.33328 6.86112H6.13883V8.66667C6.13883 8.86617 6.30045 9.02778 6.49995 9.02778C6.69944 9.02778 6.86106 8.86617 6.86106 8.66667V6.86112H8.66661C8.86611 6.86112 9.02772 6.69951 9.02772 6.50001C9.02772 6.30051 8.86611 6.1389 8.66661 6.1389Z"
                    fill="#5571C6"
                />
            </svg>
        </>
    )
    const EditIcon = ({ onClick, pxSize = 1 }) => (
        <>
            <svg
                style={{
                    cursor: 'pointer',
                    border: `${pxSize}px solid #5571C6`,
                    borderRadius: '50%',
                    padding: '3px',
                    backgroundColor: 'rgb(118 193 193 / 19%)',
                }}
                onClick={onClick}
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="10"
                viewBox="0 0 12 10"
                fill="none">
                <path
                    d="M2.57034 8.72053C2.29383 8.72053 2.03546 8.62837 1.84961 8.45721C1.6139 8.24217 1.50058 7.91741 1.54138 7.56631L1.70909 6.14439C1.74082 5.87669 1.90854 5.52121 2.10345 5.32811L5.82493 1.51438C6.75417 0.562042 7.7242 0.535711 8.70783 1.43538C9.69147 2.33505 9.71866 3.27422 8.78943 4.22656L5.06795 8.04029C4.87757 8.23778 4.524 8.4221 4.2475 8.46599L2.78791 8.70736C2.71086 8.71175 2.64286 8.72053 2.57034 8.72053ZM7.27998 1.43099C6.93095 1.43099 6.62725 1.64165 6.31902 1.95763L2.59753 5.77575C2.50688 5.86791 2.40262 6.08734 2.38449 6.21461L2.21677 7.63653C2.19864 7.78136 2.23491 7.89985 2.3165 7.97446C2.39809 8.04907 2.52048 8.0754 2.67006 8.05345L4.12964 7.81208C4.2611 7.79014 4.47867 7.67603 4.56933 7.58387L8.29081 3.77014C8.85289 3.19084 9.05687 2.65542 8.23642 1.90936C7.87379 1.57143 7.56102 1.43099 7.27998 1.43099Z"
                    fill="#5571C6"
                />
                <path
                    d="M8.53874 4.36695C8.52963 4.36695 8.51597 4.36695 8.50686 4.36695C7.08615 4.24332 5.9432 3.29814 5.72463 2.06184C5.69731 1.89833 5.82481 1.74678 6.01151 1.71886C6.1982 1.69494 6.37124 1.8066 6.40311 1.97011C6.57615 2.93523 7.46865 3.67701 8.57972 3.77273C8.76642 3.78868 8.90303 3.93624 8.88481 4.09975C8.86204 4.2513 8.71177 4.36695 8.53874 4.36695Z"
                    fill="#5571C6"
                />
            </svg>
        </>
    )
    const onTickPress = (value1, value2, fieldName = '') => {
        setIsTextFiledOpen((old) => {
            return {
                ...old,
                [value1]: false,
            }
        })
        setEditMode((old) => {
            return {
                ...old,
                [value2]: false,
            }
        })
    }
    const onCrossPress = (value1 = '', value2 = '', value3 = '') => {
        setIsTextFiledOpen((old) => {
            return {
                ...old,
                ...(value1 && { [value1]: false }),
            }
        })
        setEditMode((old) => {
            return {
                ...old,
                ...(value2 && { [value2]: false }),
            }
        })
    }

    const userDataFetch = async () => {
        try {
            const data = await me()

            setUserData(data?.data?.data)
        } catch(error) {
            console.error('error', error)
        }  
    }
    
    useLayoutEffect(() => {
        userDataFetch()
    }, [])

    // ---------------------sec phone--------------------------
    const uncheckSecNo = () => {
        formik.setFieldValue('shared_secondary_phone', true)
        setisOpenModalContactSecUncheck(false)
    }
    const uncheckSecYes = async () => {
        formik.setFieldValue('shared_secondary_phone', false)
        setisOpenModalContactSecUncheck(false)
    }
    const contactSecModalClose = () => {
        setisOpenModalContactSec(false)
        formik.setFieldValue('shared_secondary_phone', false)
    }
    const SubmitFunctionSec = () => {
        setisOpenModalContactSec(false)
    }
    // ---------------------------------------------------------------

    //for-----email----------------------------------
    const contactModalCloseEmail = () => {
        setisOpenModalEmailContact(false)
        formik.setFieldValue('shared_email', false)
    }
    const SubmitFunctionEmail = () => {
        setisOpenModalEmailContact(false)
    }
    const uncheckEmailNo = () => {
        formik.setFieldValue('shared_email', true)
        setisOpenModalEmailContactUncheck(false)
    }
    const uncheckEmailYes = async () => {
        formik.setFieldValue('shared_email', false)
        setisOpenModalEmailContactUncheck(false)
    }
    // ------------------------------------------------

    // -----For primary contact-------------------------------
    const contactModalClose = () => {
        setisOpenModalContact(false)
        formik.setFieldValue('shared_phone', false)
    }
    const SubmitFunction = () => {
        setisOpenModalContact(false)
    }

    const uncheckNo = () => {
        formik.setFieldValue('shared_phone', true)
        setisOpenModalContactUncheck(false)
    }
    const uncheckYes = async () => {
        formik.setFieldValue('shared_phone', false)
        setisOpenModalContactUncheck(false)
    }
    // -------------------------------------------------
    
    const EmptyDataLoading = () => {
        return (
            <div
                style={{
                    backgroundColor: 'white',
                    height: '70%',
                    width: '100%',
                    overflowY: 'hidden',
                    overflowX: 'hidden',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center'
                }}>
                <Box sx={{ display: 'flex', fontSize:'large' }}>
                    <CircularProgress />
                </Box>
            </div>
        )
    }
    return (
        <Dialog
            open={isOpenModal}
            fullWidth
            // className={classes.root}
            className={`${classes.root} cstm_modal`}
            sx={{
                '& .MuiDialog-paperFullWidth': {
                    maxWidth: '780px',
                },
            }}>
            <Box className={classes.modalBody}>
                {' '}
                {/* Set width */}
                <Box
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '16px',
                        gap: '8px'
                    }}
                    className='modal_header'
                >
                    <KeyboardBackspace
                        style={{ color: '#667689', cursor: 'pointer' }}
                        onClick={() => setSteps(5)}
                    />
                    <Box
                        id="modal-title"
                        className={`${classes.typoGraphy} modal_title`}
                    >
                        Verify Your Demographics
                    </Box>
                </Box>
                <Divider style={{ color: '#EAECF0' }} />
                { !userData?.id ? (
                    <EmptyDataLoading />
                ) : (
                    <>
                        <Grid
                            container
                            className='grid_container'
                            style={{
                                marginTop: '19.5px',
                                paddingLeft: '26px',
                                paddingRight: '34px',
                            }}>
                            <Grid item className={classes.gripDisplay}>
                                <div className={`${EditMode.name && EditMode.pref_name ? 'edited_holder' : 'non_edited_holder'}`}>
                                    <Typography
                                        className='typolabel_holder'
                                        style={{
                                            color: '#303E4E',
                                            fontSize: 17,
                                            fontWeight: 500,
                                            marginBottom: 5,
                                        }}>
                                        <span
                                            style={{
                                                color: '#667689',
                                                fontSize: 15,
                                                fontWeight: 400,
                                            }}>
                                            Name
                                        </span>
                                    </Typography>

                                    {EditMode?.name ? (
                                        <div className='each_form_container'>
                                            <TextField
                                                className='each_input_holder'
                                                name="first_name"
                                                placeholder="Enter First name"
                                                value={`${formik.values.first_name}`}
                                                variant="outlined"
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        'first_name',
                                                        e.target.value,
                                                    )
                                                }}
                                                onBlur={formik.handleBlur}
                                                error={
                                                    !!(
                                                        formik.errors.first_name &&
                                                        formik.touched.first_name
                                                    )
                                                }
                                                helperText={
                                                    formik.errors.first_name &&
                                                    formik.touched.first_name
                                                        ? formik.errors.first_name
                                                        : ''
                                                }
                                            />

                                            <TextField
                                                // style={{ marginLeft: '1rem' }}
                                                className='each_input_holder'
                                                name="last_name"
                                                placeholder="Enter Last name"
                                                value={`${formik.values.last_name}`}
                                                variant="outlined"
                                                onBlur={formik.handleBlur}
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        'last_name',
                                                        e.target.value,
                                                    )
                                                }}
                                                error={
                                                    formik.errors.last_name &&
                                                    formik.touched.last_name
                                                }
                                                helperText={
                                                    formik.errors.last_name &&
                                                    formik.touched.last_name
                                                        ? formik.errors.last_name
                                                        : ''
                                                }
                                            />

                                            <div className='basic_icon_outer_holder'>
                                                <span
                                                    className='basic_icon_holder'
                                                    onClick={() => {
                                                        onCrossPress('', 'name', '')
                                                        formik?.setFieldValue(
                                                            'first_name',
                                                            userData?.first_name || '',
                                                        )
                                                        formik?.setFieldValue(
                                                            'last_name',
                                                            userData?.last_name || '',
                                                        )
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </span>
                                                
                                                <span
                                                    className='basic_icon_holder'
                                                    onClick={() => {
                                                        if (
                                                            !formik?.values?.last_name ||
                                                            !formik?.values?.first_name
                                                        )
                                                            return
                                                        onTickPress('', 'name')
                                                    }}
                                                >
                                                    <DoneIcon />
                                                </span>
                                                
                                            </div>
                                        </div>
                                    ) : (
                                        <Typography className={`${classes.typoGraphyInputVal} cstm_typography_inputval`}>
                                            {formik?.values?.first_name || 'Loading...'}{' '}
                                            {formik?.values?.last_name || ''}
                                        </Typography>
                                    )}

                                    {formik?.values?.preferred_name || EditMode?.pref_name ? (
                                        <>
                                            <Typography
                                                className='typolabel_holder'
                                                style={{
                                                    marginTop: '14px',
                                                    color: '#667689',
                                                    fontSize: 15,
                                                    fontWeight: 400,
                                                    marginBottom: '5px',
                                                }}>
                                                Preferred Name
                                            </Typography>
                                            {!EditMode?.pref_name && (
                                                <Typography className={`${classes.typoGraphyInputVal} cstm_typography_inputval`}>
                                                    {formik?.values?.preferred_name || 'Loading...'}
                                                </Typography>
                                            )}
                                        </>
                                    ) : (
                                        !EditMode?.pref_name && (
                                            <Typography
                                                style={{
                                                    marginTop: '10px',
                                                }}>
                                                <span
                                                    onClick={() => {
                                                        setIsTextFiledOpen((old) => {
                                                            return {
                                                                ...old,
                                                                isNameVisible: true,
                                                            }
                                                        })
                                                        setEditMode((old) => {
                                                            return { ...old, pref_name: true }
                                                        })
                                                    }}
                                                    className={
                                                        isTextFiledOpen.isNameVisible
                                                            ? classes.hiddenBlueLine
                                                            : classes.blueToBeEditLine
                                                    }>
                                                    {!isTextFiledOpen.isNameVisible && <AddIcon />}
                                                    &nbsp;{' '}
                                                    {isTextFiledOpen.isNameVisible
                                                        ? 'Preferred Name'
                                                        : 'Add Preferred Name'}
                                                </span>
                                            </Typography>
                                        )
                                    )}

                                    {(isTextFiledOpen.isNameVisible || EditMode?.pref_name) && (
                                        <div className='each_form_container'>
                                            <TextField
                                                className='each_input_holder'
                                                name="preferred_name"
                                                placeholder="Enter Preferred Name"
                                                value={formik.values.preferred_name}
                                                variant="outlined"
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        'preferred_name',
                                                        e.target.value,
                                                    )
                                                }}
                                            />
                                            <div className='basic_icon_outer_holder'>
                                                <span
                                                    className='basic_icon_holder'
                                                    onClick={() => {
                                                        formik?.setFieldValue(
                                                            'preferred_name',
                                                            userData?.preferred_name || '',
                                                        )
                                                        onCrossPress(
                                                            'isNameVisible',
                                                            'pref_name',
                                                            '',
                                                        )
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </span>
                                                
                                                <span
                                                    className='basic_icon_holder'
                                                    onClick={() => {
                                                        onTickPress('isNameVisible', 'pref_name')
                                                    }}
                                                >
                                                    <DoneIcon />
                                                </span>
                                                
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='edit_icon_holder'>
                                    <EditIcon
                                        onClick={() =>
                                            setEditMode((old) => {
                                                return { ...old, name: true, pref_name: true }
                                            })
                                        }
                                        pxSize={EditMode?.pref_name || EditMode?.name ? 3 : 1}
                                    />
                                </div>
                            </Grid>

                            {/* End of Name Edit Section..................................... */}

                            <Divider
                                style={{
                                    marginTop: EditMode?.pref_name ? 7 : 19,
                                    color: '#EAECF0',
                                    width: '100%',
                                }}
                            />

                            <Grid
                                item
                                // xs={12}
                                style={{
                                    marginTop: '20px',
                                }}
                                className={classes.gripDisplay}>
                                <div className={`${isTextFiledOpen.is_Dob_visible ? 'edited_holder' : 'non_edited_holder'}`}>
                                    <Typography
                                        className='typolabel_holder'
                                        style={{
                                            color: '#303E4E',
                                            fontSize: 17,
                                            fontWeight: 500,
                                            marginBottom: 5,
                                        }}>
                                        <span
                                            style={{
                                                color: '#667689',
                                                fontSize: 15,
                                                fontWeight: 400,
                                            }}>
                                            Date Of Birth
                                        </span>
                                    </Typography>

                                    {!isTextFiledOpen.is_Dob_visible && <Typography
                                        className={`${classes.typoGraphyInputVal} cstm_typography_inputval`}
                                        style={
                                            {
                                                // marginBottom: '10px',
                                            }
                                        }>
                                        {moment(formik?.values?.dob).format('MM/DD/YYYY') ||
                                            'Loading...'}
                                    </Typography>}

                                    {isTextFiledOpen.is_Dob_visible && (
                                        <div className='each_form_container'>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    className="ba-input-fields each_input_holder ba-date-input"
                                                    inputVariant="outlined"
                                                    variant="inline"
                                                    format="MM/DD/YYYY"
                                                    name="dob"
                                                    maxDate={new Date()}
                                                    autoOk={true}
                                                    placeholder={'MM/DD/YYYY'}
                                                    value={formik.values.dob}
                                                    onChange={(e) => {
                                                        if (e) {
                                                            formik.setFieldValue(
                                                                'dob',
                                                                moment(e).format().split('T')[0],
                                                            )
                                                        }
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <div className='basic_icon_outer_holder'>
                                                <span
                                                    className='basic_icon_holder'
                                                    onClick={() => {
                                                        onCrossPress('is_Dob_visible', '')
                                                        formik?.setFieldValue(
                                                            'dob',
                                                            userData?.dob || '',
                                                        )
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </span>
                                               
                                                <span
                                                    className='basic_icon_holder'
                                                    onClick={() =>
                                                        onTickPress('is_Dob_visible', '')
                                                    }
                                                >
                                                    <DoneIcon />
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='edit_icon_holder'>
                                    <EditIcon
                                        onClick={() =>
                                            setIsTextFiledOpen((old) => {
                                                return {
                                                    ...old,
                                                    is_Dob_visible: true,
                                                }
                                            })
                                        }
                                        pxSize={isTextFiledOpen.is_Dob_visible ? 3 : 1}
                                    />
                                </div>
                            </Grid>

                            {/* End of DOB Edit Section..................................... */}

                            <Divider
                                style={{ marginTop: '21px', color: '#EAECF0', width: '100%' }}
                            />

                            <Grid
                                item
                                // xs={12}
                                className={classes.gripDisplay}
                                style={{ marginTop: '20px' }}>
                                <div className={`${EditMode?.phone_number && EditMode?.secondary_phone_number ? 'edited_holder' : 'non_edited_holder'}`}>
                                    <Typography
                                        className='typolabel_holder'
                                        style={{
                                            color: '#303E4E',
                                            fontSize: 17,
                                            fontWeight: 500,
                                            marginBottom: 5,
                                        }}>
                                        <span
                                            style={{
                                                color: '#667689',
                                                fontSize: 15,
                                                fontWeight: 400,
                                            }}>
                                            Phone Number
                                        </span>
                                    </Typography>

                                    {EditMode?.phone_number ? (
                                        <div className='each_form_container'>
                                            <TextField
                                                className='each_input_holder'
                                                name="phone_number"
                                                placeholder="Enter Phone Number"
                                                value={formik.values.phone_number}
                                                onBlur={formik.handleBlur}
                                                variant="outlined"
                                                inputProps={{ maxLength: 10 }}
                                                onChange={(e) => {
                                                    if (isNaN(+e?.nativeEvent?.data)) return
                                                    formik.setFieldValue(
                                                        'phone_number',
                                                        formatPhoneNumber(e.target.value),
                                                    )
                                                }}
                                                error={
                                                    formik.errors.phone_number &&
                                                    formik.touched.phone_number
                                                }
                                                helperText={
                                                    formik.errors.phone_number &&
                                                    formik.touched.phone_number
                                                        ? formik.errors.phone_number
                                                        : ''
                                                }
                                            />
                                            <div className='basic_icon_outer_holder'>
                                                <span
                                                    className='basic_icon_holder'
                                                    onClick={() => {
                                                        onCrossPress('', 'phone_number')
                                                        formik?.setFieldValue(
                                                            'phone_number',
                                                            userData?.phone_number || '',
                                                        )
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </span>
                                               
                                                <span
                                                    className='basic_icon_holder'
                                                    onClick={() => {
                                                        if (!formik?.values?.phone_number) return
                                                        onTickPress('', 'phone_number')
                                                    }}
                                                >
                                                    <DoneIcon />
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', flexWrap: 'wrap'}}>
                                            <Typography style={{ width: '100%', padding: '10px 0' }} className={`${classes.typoGraphyInputVal} cstm_typography_inputval`}>
                                                {formatPhoneNumber(formik?.values?.phone_number) ||
                                                    'Loading...'}
                                            </Typography>
                                            <CheckboxOption
                                                checked={formik.values.shared_phone}
                                                sx={{
                                                    '& .MuiFormControlLabel': {
                                                        marginRight: '0px',
                                                    },
                                                }}
                                                customStyle={{
                                                    marginRight: '0px',
                                                    height: '14px',
                                                    width: '14px',
                                                    paddingTop: '2px',
                                                }}
                                                option={{
                                                    label: '',
                                                    value: false,
                                                }}
                                                onChange={(event) => {
                                                    formik.setFieldValue(
                                                        'shared_phone',
                                                        event.target.checked,
                                                    )
                                                    setisOpenModalContact(event.target.checked)
                                                    if (!event.target.checked) {
                                                        setisOpenModalContactUncheck(true)
                                                    }
                                                }}
                                            />
                                            <Typography style={{ color: '#303E4E', fontSize: 16 }}>
                                                Shared phone
                                            </Typography>
                                        </div>
                                    )}

                                    {formik?.values?.secondary_phone_number ||
                                    EditMode?.secondary_phone_number ? (
                                        <>
                                            <Typography
                                                className='typolabel_holder'
                                                style={{
                                                    marginTop: '10px',
                                                }}>
                                                <span
                                                    style={{
                                                        color: '#667689',
                                                        fontSize: 15,
                                                        fontWeight: 400,
                                                    }}>
                                                    Secondary Number
                                                </span>
                                            </Typography>
                                            {!EditMode?.secondary_phone_number && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                    }}>
                                                    <Typography
                                                        className={`${classes.typoGraphyInputVal} cstm_typography_inputval`}>
                                                        {formatPhoneNumber(
                                                            formik?.values?.secondary_phone_number,
                                                        ) || 'Loading...'}
                                                    </Typography>

                                                    <CheckboxOption
                                                        checked={
                                                            formik.values.shared_secondary_phone
                                                        }
                                                        sx={{
                                                            '& .MuiFormControlLabel': {
                                                                marginRight: '0px',
                                                            },
                                                        }}
                                                        customStyle={{
                                                            marginLeft: '17px',
                                                            marginRight: '0px',
                                                            height: '14px',
                                                            width: '14px',
                                                            paddingTop: '2px',
                                                        }}
                                                        option={{
                                                            label: '',
                                                            value: false,
                                                        }}
                                                        onChange={(event) => {
                                                            formik.setFieldValue(
                                                                'shared_secondary_phone',
                                                                event.target.checked,
                                                            )
                                                            setisOpenModalContactSec(
                                                                event.target.checked,
                                                            )
                                                            if (!event.target.checked) {
                                                                setisOpenModalContactSecUncheck(
                                                                    true,
                                                                )
                                                            }
                                                        }}
                                                    />
                                                    <Typography
                                                        style={{ color: '#303E4E', fontSize: 16 }}>
                                                        Shared phone
                                                    </Typography>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        !EditMode?.secondary_phone_number && (
                                            <Typography
                                                style={{
                                                    marginTop: '10px',
                                                }}>
                                                <span
                                                    onClick={() => {
                                                        setIsTextFiledOpen((old) => {
                                                            return {
                                                                ...old,
                                                                isSec_Phone_visible: true,
                                                            }
                                                        })
                                                        setEditMode((old) => {
                                                            return {
                                                                ...old,
                                                                secondary_phone_number: true,
                                                            }
                                                        })
                                                    }}
                                                    className={
                                                        isTextFiledOpen.isSec_Phone_visible
                                                            ? classes.hiddenBlueLine
                                                            : classes.blueToBeEditLine
                                                    }>
                                                    {!isTextFiledOpen.isSec_Phone_visible && (
                                                        <AddIcon />
                                                    )}
                                                    &nbsp;{' '}
                                                    {isTextFiledOpen.isSec_Phone_visible
                                                        ? 'Secondary Number'
                                                        : 'Add Secondary Number'}
                                                </span>
                                            </Typography>
                                        )
                                    )}

                                    {(isTextFiledOpen.isSec_Phone_visible ||
                                        EditMode?.secondary_phone_number) && (
                                        <div className='each_form_container'>
                                            <TextField
                                                className='each_input_holder'
                                                name="secondary_phone_number"
                                                placeholder="Enter Secondary Number"
                                                value={formik.values.secondary_phone_number}
                                                variant="outlined"
                                                inputProps={{ maxLength: 10 }}
                                                onChange={(e) => {
                                                    if (isNaN(+e?.nativeEvent?.data)) return
                                                    formik.setFieldValue(
                                                        'secondary_phone_number',
                                                        formatPhoneNumber(e.target.value),
                                                    )
                                                }}
                                            />
                                            <div className='basic_icon_outer_holder'>
                                                <span
                                                    className='basic_icon_holder'
                                                    onClick={() => {
                                                        formik?.setFieldValue(
                                                            'secondary_phone_number',
                                                            userData?.secondary_phone_number || '',
                                                        )
                                                        onCrossPress(
                                                            'isSec_Phone_visible',
                                                            'secondary_phone_number',
                                                        )
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </span>
                                               
                                                <span
                                                    className='basic_icon_holder'
                                                    onClick={() => {
                                                        onTickPress(
                                                            'isSec_Phone_visible',
                                                            'secondary_phone_number',
                                                        )
                                                    }}
                                                >
                                                    <DoneIcon />
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='edit_icon_holder'>
                                    <EditIcon
                                        onClick={() =>
                                            setEditMode((old) => {
                                                return {
                                                    ...old,
                                                    phone_number: true,
                                                    secondary_phone_number: true,
                                                }
                                            })
                                        }
                                        pxSize={
                                            EditMode?.phone_number ||
                                            EditMode?.secondary_phone_number
                                                ? 3
                                                : 1
                                        }
                                    />
                                </div>
                            </Grid>

                            <Divider
                                style={{ marginTop: '21px', color: '#EAECF0', width: '100%' }}
                            />

                            <Grid 
                                item 
                                // xs={12} 
                                style={{ marginTop: '19px' }} 
                                className={classes.gripDisplay}
                            >
                                <div className=''>
                                    <Typography
                                        className='typolabel_holder'
                                        style={{
                                            color: '#303E4E',
                                            fontSize: 17,
                                            fontWeight: 500,
                                        }}>
                                        <span
                                            style={{
                                                color: '#667689',
                                                fontSize: 15,
                                                fontWeight: 400,
                                            }}>
                                            Email
                                        </span>
                                    </Typography>

                                    <div
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                        }}>
                                        <Typography style={{ width: '100%', padding: '10px 0' }} className={`${classes.typoGraphyInputVal} cstm_typography_inputval`}>
                                            {formik?.values?.email}
                                        </Typography>

                                        <CheckboxOption
                                            checked={formik.values.shared_email}
                                            sx={{
                                                '& .MuiFormControlLabel': {
                                                    marginRight: '0px',
                                                },
                                            }}
                                            customStyle={{
                                                marginRight: '0px',
                                                height: '14px',
                                                width: '14px',
                                                paddingTop: '2px',
                                            }}
                                            option={{
                                                label: '',
                                                value: false,
                                            }}
                                            onChange={(event) => {
                                                formik.setFieldValue(
                                                    'shared_email',
                                                    event.target.checked,
                                                )
                                                setisOpenModalEmailContact(event.target.checked)
                                                if (!event.target.checked) {
                                                    setisOpenModalEmailContactUncheck(true)
                                                }
                                            }}
                                        />
                                        <Typography style={{ color: '#303E4E', fontSize: 16 }}>
                                            Shared email
                                        </Typography>
                                    </div>
                                </div>
                                <div></div>
                            </Grid>

                            <Divider
                                style={{ marginTop: '21px', color: '#EAECF0', width: '100%' }}
                            />

                            <Grid 
                                item 
                                // xs={12} 
                                style={{ marginTop: '19px' }}
                                className={classes.gripDisplay}
                            >
                                <div className={`${EditMode?.address ? 'edited_holder' : 'non_edited_holder'}`}>
                                    <Typography
                                        className='typolabel_holder'
                                        style={{
                                            color: '#303E4E',
                                            fontSize: 17,
                                            fontWeight: 500,
                                            marginBottom: 5,
                                        }}>
                                        <span
                                            style={{
                                                color: '#667689',
                                                fontSize: 15,
                                                fontWeight: 400,
                                            }}>
                                            Address
                                        </span>
                                    </Typography>

                                    {EditMode?.address ? (
                                        <>
                                            <div className='each_form_container'>
                                                <div className='input_holder'>
                                                    <label className='cstm_label_holder d-lg-none'>Address Line 1 <span className='required'>*</span></label>
                                                    <input
                                                        style={{marginTop: '0'}}
                                                        className={`each_input_holder ${classes.isEditMode}`}
                                                        type="text"
                                                        value={formik.values.address1}
                                                        placeholder="Enter Address Line1"
                                                        onChange={(e) =>
                                                            formik?.setFieldValue(
                                                                'address1',
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                    <span className={classes.errorLine}>
                                                        {!formik.values?.address1 &&
                                                        formik.errors.address1
                                                            ? 'Enter Address Line 1'
                                                            : ''}
                                                    </span>
                                                </div>
                                                <div className='input_holder'>
                                                    <label className='cstm_label_holder d-lg-none'>Address Line 2</label>
                                                    <input
                                                        style={{marginTop: '0'}}
                                                        className={`each_input_holder ${classes.isEditMode}`}
                                                        type="text"
                                                        value={formik.values.address2}
                                                        placeholder="Enter Address Line2"
                                                        onChange={(e) =>
                                                            formik?.setFieldValue(
                                                                'address2',
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div style={{margin: '16px 0'}}>
                                                <label className='cstm_label_holder d-lg-none'>State <span className='required'>*</span></label>
                                                <select
                                                    style={{ width: '100%', marginTop: '0' }}
                                                    className={`address_select_holder ${classes.isEditMode}`}
                                                    value={formik?.values?.state}
                                                    onChange={(e) =>
                                                        formik?.setFieldValue(
                                                            'state',
                                                            e.target.value,
                                                        )
                                                    }>
                                                    {stateList?.map((state) => {
                                                        return (
                                                            <option
                                                                className="select_option"
                                                                key={state?.state}>
                                                                {state?.state}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                            </div>

                                            <div className='each_form_container'>
                                                <div className='each_half_holder'>
                                                    <label className='cstm_label_holder d-lg-none'>City <span className='required'>*</span></label>
                                                    <input
                                                        style={{ marginTop: '0' }}
                                                        className={classes.isEditMode}
                                                        type="text"
                                                        placeholder="Enter City"
                                                        value={formik.values.city}
                                                        onChange={(e) =>
                                                            formik?.setFieldValue(
                                                                'city',
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                    <span className={classes.errorLine}>
                                                        {!formik.values?.city &&
                                                        formik.errors.city
                                                            ? 'Enter City Name'
                                                            : ''}
                                                    </span>
                                                </div>
                                                <div className='each_half_holder'> 
                                                    <label className='cstm_label_holder d-lg-none'>Zip <span className='required'>*</span></label>
                                                    <input
                                                        style={{ width: '100%', marginTop: '0' }}
                                                        className={classes.isEditMode}
                                                        type="text"
                                                        maxLength={10}
                                                        placeholder="Enter Zip Code"
                                                        value={formik.values.zip}
                                                        onChange={(e) => {
                                                            if (
                                                                isNaN(+e?.nativeEvent?.data)
                                                            )
                                                                return
                                                            formik?.setFieldValue(
                                                                'zip',
                                                                e.target.value,
                                                            )
                                                        }}
                                                    />
                                                        {/* <div
                                                            style={{
                                                                marginLeft: '5px',
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                gap: '4px',
                                                            }}>
                                                            <ClearOutlinedIcon
                                                                onClick={() => {
                                                                    onCrossPress('', 'address')
                                                                    ;[
                                                                        'address1',
                                                                        'address2',
                                                                        'city',
                                                                        'state',
                                                                        'zip',
                                                                    ].forEach((e) => {
                                                                        formik.setFieldValue(
                                                                            e,
                                                                            userData?.[e] || '',
                                                                        )
                                                                    })
                                                                }}
                                                                style={{
                                                                    color: '#667689',
                                                                    fontSize: '15',
                                                                    fontWeight: 400,
                                                                    cursor: 'pointer',
                                                                    border: '1px solid #A3ABB5',
                                                                }}
                                                            />
                                                            <CheckOutlinedIcon
                                                                onClick={() => {
                                                                    if (
                                                                        !formik?.values
                                                                            ?.address1 ||
                                                                        !formik?.values
                                                                            ?.state ||
                                                                        !formik?.values?.city ||
                                                                        !formik?.values?.zip
                                                                    ) {
                                                                        return
                                                                    }
                                                                    onTickPress('', 'address')
                                                                }}
                                                                style={{
                                                                    color: '#667689',
                                                                    fontSize: '15',
                                                                    fontWeight: 400,
                                                                    cursor: 'pointer',
                                                                    border: '1px solid #A3ABB5',
                                                                }}
                                                            />
                                                        </div> */}
                                                    <span className={classes.errorLine}>
                                                        {!formik.values?.zip &&
                                                        formik.errors.zip
                                                            ? 'Enter Zip'
                                                            : ''}
                                                    </span>
                                                </div>

                                                <div className='basic_icon_outer_holder'>
                                                    <span
                                                        className='basic_icon_holder'
                                                        onClick={() => {
                                                            onCrossPress('', 'address')
                                                            ;[
                                                                'address1',
                                                                'address2',
                                                                'city',
                                                                'state',
                                                                'zip',
                                                            ].forEach((e) => {
                                                                formik.setFieldValue(
                                                                    e,
                                                                    userData?.[e] || '',
                                                                )
                                                            })
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </span>
                                                
                                                    <span
                                                        className='basic_icon_holder'
                                                        onClick={() => {
                                                            if (
                                                                !formik?.values
                                                                    ?.address1 ||
                                                                !formik?.values
                                                                    ?.state ||
                                                                !formik?.values?.city ||
                                                                !formik?.values?.zip
                                                            ) {
                                                                return
                                                            }
                                                            onTickPress('', 'address')
                                                        }}
                                                    >
                                                        <DoneIcon />
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <Typography
                                                className={`${classes.typoGraphyInputVal} cstm_typography_inputval`}
                                                style={{ marginBottom: 5 }}>
                                                {formik?.values?.address1 || 'Loading...'}
                                            </Typography>
                                            <Typography
                                                className={`${classes.typoGraphyInputVal} cstm_typography_inputval`}
                                                style={{ marginBottom: 5 }}>
                                                {formik?.values?.address2 || ''}
                                            </Typography>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    flexGrow: 1,
                                                    flexShrink: 1,
                                                    flexWrap: 'wrap',
                                                    columnGap: '14px',
                                                    marginBottom: 5,
                                                }}>
                                                <Typography
                                                    className={`${classes.typoGraphyInputVal} cstm_typography_inputval`}>
                                                    {formik?.values?.state || 'Loading...'}
                                                </Typography>
                                                <Typography
                                                    className={`${classes.typoGraphyInputVal} cstm_typography_inputval`}>
                                                    {formik?.values?.city || 'Loading...'}
                                                </Typography>
                                                <Typography
                                                    className={`${classes.typoGraphyInputVal} cstm_typography_inputval`}>
                                                    {formik?.values?.zip || 'Loading...'}
                                                </Typography>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className='edit_icon_holder'>
                                    <EditIcon
                                        onClick={() => {
                                            setEditMode((old) => {
                                                return { ...old, address: true }
                                            })
                                        }}
                                        pxSize={EditMode?.address ? 3 : 1}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider style={{ marginTop: '21px', color: '#EAECF0' }} />
                            </Grid>
                        </Grid>

                        <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px', padding: '10px 0' }} className='bottom_btn_holder'>
                            <Button
                                disabled={isLoading}
                                variant="contained"
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'inline-flex',
                                    // width: '100%',
                                    height: '43px',
                                    background: '#FFF',
                                    borderRadius: '5px',
                                    color: '#5571C6',
                                    border: '1px solid #5571C6',
                                    textTransform: 'none',
                                }}
                                onClick={() => setSteps(5)}>
                                {'Back'}
                            </Button>
                                
                            <Button
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'inline-flex',
                                    opacity: isLoading ? 0.8 : 2,
                                    // width: '100%',
                                    height: '43px',
                                    background: '#5571C6',
                                    borderRadius: '5px',
                                    color: '#FFF',
                                    border: '1px solid #5571C6',
                                    textTransform: 'none',
                                }}
                                disabled={isLoading || !formik?.isValid}
                                className="btn btn-success btn-lg ms-2"
                                onClick={() => {
                                    formik.handleSubmit()
                                }}>
                                {isLoading
                                    ? 'Loading...'
                                    : 'Yes, this information is correct'}
                            </Button>
                        </div>
                    </>
                )}
            </Box>
            <SharedContactModal
                isOpenModalContact={isOpenModalContact}
                contactModalClose={contactModalClose}
                SubmitFunction={SubmitFunction}
                patientInfo={userData}
                phone_number={userData?.phone_number || formik?.values?.phone_number}
                height={'380px'}
                heading={'Shared Phone Number'}
            />
            <UncheckSharedModal
                isOpenModalContact={isOpenModalContactUncheck}
                contactModalClose={uncheckNo}
                patientInfo={userData}
                SubmitFunction={uncheckYes}
                height={'350px'}
                heading={'Shared Phone Number'}
                // isLoading={isLoading}
                text="You are about to uncheck your shared phone number consent and indicate this is no longer a shared number."
            />

            <SharedContactModal
                isOpenModalContact={isOpenModalContactSec}
                contactModalClose={contactSecModalClose}
                SubmitFunction={SubmitFunctionSec}
                patientInfo={userData}
                phone_number={
                    userData?.secondary_phone_number || formik?.values?.secondary_phone_number
                }
                height={'380px'}
                heading={'Shared Phone Number'}
            />
            <UncheckSharedModal
                isOpenModalContact={isOpenModalContactSecUncheck}
                contactModalClose={uncheckSecNo}
                patientInfo={userData}
                SubmitFunction={uncheckSecYes}
                height={'350px'}
                heading={'Shared Phone Number'}
                // isLoading={isLoading}
                text="You are about to uncheck your shared secondary phone number consent and indicate this is no longer a shared number."
            />

            <SharedContactModal
                isOpenModalContact={isOpenModalEmailContact}
                contactModalClose={contactModalCloseEmail}
                SubmitFunction={SubmitFunctionEmail}
                patientInfo={userData}
                phone_number={userData?.email || formik?.values?.email}
                height={'380px'}
                heading={'Shared Email Inbox'}
            />
            <UncheckSharedModal
                isOpenModalContact={isOpenModalEmailContactUncheck}
                contactModalClose={uncheckEmailNo}
                patientInfo={userData}
                SubmitFunction={uncheckEmailYes}
                height={'330px'}
                // isLoading={isLoading}
                text="You are about to uncheck your shared email consent and indicate this is no longer a shared email inbox."
                heading={'Shared Email Inbox'}
            />
        </Dialog>
    )
}
