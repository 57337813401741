let { config } = require('../../constants')
let axios = require('axios')

const axiosInstance = axios.create({
    baseURL: config.dev.baseURLV2,
})

axiosInstance.interceptors.request.use(
    (requestConfig) => {
        // console.log(requestConfig.data)
        const token = localStorage.getItem('access_token')
        const tempToken = localStorage.getItem('refresh_token')
        if (!token && !!tempToken) {
            requestConfig.headers['Authorization'] = `Bearer ${tempToken}`
        } else if (!!token || requestConfig.data?.token) {
            requestConfig.headers['Authorization'] = `Bearer ${token || requestConfig.data?.token}`
        }
        if (requestConfig.data?.source) {
            requestConfig['cancelToken'] = requestConfig.data.source.token
            delete requestConfig.data.source
        }
        return requestConfig
    },
    (error) => {
        return Promise.reject(error)
    },
)

axiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.response?.status === 401) {
            localStorage.clear()
            window.location.href = '/signin'
        }
        
        return Promise.reject(error.response)
    },
)

function getService(url) {
    return axiosInstance.get(url)
}

function postService(url, params, option) {
    return axiosInstance.post(url, params, option)
}

function putService(url, params) {
    return axiosInstance.request({
        url: url,
        method: 'PUT',
        data: params,
    })
}

function deleteService(url, params) {
    return axiosInstance.request({
        url: url,
        method: 'DELETE',
        data: params,
    })
}
function authGetService(url, params) {
    return axiosInstance.get(url, { data: params })
}
module.exports = {
    getService: getService,
    postService: postService,
    putService: putService,
    deleteService: deleteService,
    authGetService,
}
