import React from 'react'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import Divider from '@mui/material/Divider'
import {
    Grid,
    Tabs,
    Tab,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Modal,
    Box,
    TextField,
    Button,
} from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import CheckboxOption from '../../../../ClinicalNotes/reusable/CheckboxOptions'
import './intakeModal.scss'
import { useNotification } from '../../../MaterialUi/Notification'
const truncateToPositiveInteger = (value) => {
    return Math.floor(Math.max(0, value))
}
const CustomTab = withStyles((theme) => ({
    root: {
        minWidth: 0,
        maxWidth: 'unset !important',
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        '& .MuiTab-root': {
            '& .MuiTab-wrapper': {
                background: 'transparent',
                // fontWeight: 500,
                fontFamily: 'Rubik',
                borderBottom: `4px solid #5571C6`,
                paddingBottom: '8px',
                paddingTop: '8px',
                color: '#242424 !important',
            },
        },
        '&.Mui-selected': {
            '& .MuiTab-wrapper': {
                background: 'transparent',
                fontWeight: 600,
                fontFamily: 'Rubik',
                borderBottom: `4px solid #5571C6`,
                paddingBottom: '8px',
                paddingTop: '8px',
                color: '#242424',
            },
        },
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
}))((props) => <Tab {...props} />)
function TabPanel(props) {
    const { children, value, index, tabContentRef, setIsScroll, ...other } = props
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            className="modal-container"
            ref={tabContentRef}
            onScroll={() => setIsScroll(true)}
            {...other}>
            {value === index && (
                <Box sx={{ p: 3 }} className="modal-content">
                    {children}
                </Box>
            )}
        </div>
    )
}
const IntakeModalForm = ({
    open,
    toggleOpen,
    onModalClose,
    currentTab,
    setCurrentTab,
    tabContentRefs,
    isCheckboxEnabled,
    setIsCheckboxEnabled,
    setScrolledTabs,
    scrolledTabs,
    values,
    setValues,
    sign,
    setSign,
}) => {
    const [isScroll, setIsScroll] = React.useState(false)
    const notification = useNotification()
    React.useEffect(() => {
        const handleScroll = (index) => {
            if (!tabContentRefs.current[index]) {
                return
            }
            const contentHeight = tabContentRefs.current[index].clientHeight
            const scrollHeight = tabContentRefs.current[index].scrollHeight
            const scrollPosition = tabContentRefs.current[index].scrollTop
            const bottomPosition = scrollHeight - scrollPosition - contentHeight

            // Check if the user has scrolled to the bottom of the tab's content
            if (
                truncateToPositiveInteger(bottomPosition) >= 0 &&
                truncateToPositiveInteger(bottomPosition) <= 10 &&
                scrollPosition > 10
            ) {
                if (index !== 5) {
                    setCurrentTab(index + 1)
                    tabContentRefs.current[index + 1].scrollTop = 10
                }

                setScrolledTabs((prev) => {
                    // Store the index of the tab if it hasn't been stored already
                    if (!prev.includes(index)) {
                        return [...new Set([...prev, ...[index]])]
                    }
                    return prev
                })
            } else if (scrollPosition === 0 && index > 0 && bottomPosition !== 0) {
                setCurrentTab(index - 1)
            }
        }

        // Check if all tabs have been scrolled through
        setIsCheckboxEnabled(scrolledTabs?.length === 6)

        // Add scroll event listeners to each tab content

        tabContentRefs?.current?.forEach((_, index) => {
            tabContentRefs?.current[index]?.addEventListener('scroll', () => handleScroll(index))
        })

        // Remove event listeners when component unmounts
        return () => {
            tabContentRefs?.current?.forEach((_, index) => {
                tabContentRefs?.current[index]?.removeEventListener('scroll', () =>
                    handleScroll(index),
                )
            })
        }
    }, [scrolledTabs, currentTab, open, isScroll])

    React.useEffect(() => {
        if (tabContentRefs && tabContentRefs.current[currentTab]) {
            tabContentRefs.current[currentTab].scrollTop = 10
            if (
                tabContentRefs.current[currentTab].scrollHeight ===
                tabContentRefs.current[currentTab].clientHeight
            ) {
                setScrolledTabs((prev) => {
                    // Store the index of the tab if it hasn't been stored already
                    if (!prev.includes(currentTab)) {
                        return [...prev, currentTab]
                    }
                    return prev
                })
            }
            setIsCheckboxEnabled(scrolledTabs.length === 6)
        }
    }, [currentTab, tabContentRefs, scrolledTabs])

    const handleChange = (index) => {
        setCurrentTab(index)
    }
    const handleSaveSign = () => {
        if (sign) {
            setValues('termsSign', sign)
            setValues('releaseOfInfo', true)
            onModalClose()
        } else {
            notification('Please sign here', 'error')
        }
    }
    return (
        <Modal
            className='cstm_modal'
            open={open}
            onClose={() => {
                toggleOpen(false)
            }}>
            <div className="intake-modal-container">
                <div className="popup-close-wrapper heading_holder">
                    <KeyboardBackspace
                        id="auto-apt-success-close"
                        onClick={onModalClose}
                        role="presentation"
                    />
                    <span className="head-line modal_title">Terms and Conditions</span>
                </div>
                <Divider orientation="horizontal" className="modal-divider" />
                <div
                    container
                    className='tab-container'
                   >
                    <Tabs
                        value={currentTab}
                        onChange={(_, value) => handleChange(value)}
                        TabIndicatorProps={{ style: { display: 'none' } }}>
                        <CustomTab
                            className="tabText"
                            style={{
                                background: 'none',
                                textTransform: 'capitalize',
                                fontFamily: 'Rubik',
                                color: '#424242',
                                fontWeight: 400,
                            }}
                            label="Terms & Conditions"
                        />
                        <CustomTab
                            className="tabText"
                            style={{
                                background: 'none',
                                textTransform: 'capitalize',
                                fontFamily: 'Rubik',
                                color: '#424242',
                                fontWeight: 400,
                            }}
                            label="Informed Consent for Medical Services"
                        />
                        <CustomTab
                            className="tabText"
                            style={{
                                background: 'none',
                                textTransform: 'capitalize',
                                fontFamily: 'Rubik',
                                color: '#424242',
                                fontWeight: 400,
                            }}
                            label="Telehealth Consent"
                        />
                        <CustomTab
                            className="tabText"
                            style={{
                                background: 'none',
                                textTransform: 'capitalize',
                                fontFamily: 'Rubik',
                                color: '#424242',
                                fontWeight: 400,
                            }}
                            label="Privacy Policy"
                        />
                        <CustomTab
                            className="tabText"
                            style={{
                                background: 'none',
                                textTransform: 'capitalize',
                                fontFamily: 'Rubik',
                                color: '#424242',
                                fontWeight: 400,
                            }}
                            label="Notice of Privacy Practices"
                        />
                        <CustomTab
                            className="tabText"
                            style={{
                                background: 'none',
                                textTransform: 'capitalize',
                                fontFamily: 'Rubik',
                                color: '#424242',
                                fontWeight: 400,
                            }}
                            label="Release of Information"
                        />
                    </Tabs>
                    <Grid container className='cstm_grid_container_holder'>
                        <TabPanel
                            value={currentTab}
                            index={0}
                            tabContentRef={(ref) => (tabContentRefs.current[0] = ref)}
                            setIsScroll={setIsScroll}>
                            <div>
                                <h3 style={{ textAlign: 'center' }}>Terms and Conditions</h3>
                                <p>
                                    These Terms and Conditions ("Terms and Conditions") form a legal
                                    agreement between TeleMed2U Inc. and you, the individual ("you"
                                    and "your") concerning your access to and use of the various
                                    services offered through the Website and any other website or
                                    online service that TeleMed2U Inc. operates (as defined below).
                                    Use of any of the Websites or Platforms constitutes your
                                    acceptance of these Terms and Conditions and the Privacy Policy.
                                </p>
                                <p class="paragraph-20 para_links">
                                    TeleMed2U Inc., on its own behalf and on behalf of its
                                    affiliates and/or wholly owned subsidiaries including but not
                                    limited to TeleMed2U, Inland Endocrine, Specialty Clinic of
                                    Austin, Medzino, (collectively referred to as "TeleMed2U Inc.",
                                    "we", "us", or "our"), TeleMed2U Inc. makes certain telehealth
                                    related information available to you and/or facilitates your
                                    access to telemedicine and expert medical services ("TeleMed2U
                                    Inc. Services"). TeleMed2U Inc. owns and operates (i) various
                                    publicly available websites, including without limitation,{' '}
                                    <a href="http://www.telemed2u.com">www.telemed2u.com</a>,{' '}
                                    <a href="http://www.specialtyaustin.com">
                                        www.specialtyaustin.com
                                    </a>
                                    ,{' '}
                                    <a href="http://www.inlandendocrine.com">
                                        www.inlandendocrine.com
                                    </a>
                                    , and{' '}
                                    <a href="http://www.medzino.com/us/">www.medzino.com/us/</a>{' '}
                                    (collectively, "Websites") and (ii) various web-based and mobile
                                    applications that require you to create an account in order to
                                    use a TeleMed2U Inc. Service (collectively, "Platforms").
                                </p>
                                <p>
                                    By using the Websites and/or the Platforms, you agree to these
                                    Terms and Conditions. If you do not agree to these Terms and
                                    Conditions, you should immediately suspend all use of and access
                                    to all of the Websites and Platforms. At any time, we may change
                                    these Terms or modify any features of the Services.&nbsp; The
                                    most current version of the Terms can be viewed by clicking on
                                    the “Terms and Conditions” link.
                                </p>
                                <h3>Use of Our Websites/Platforms</h3>
                                <p>
                                    TeleMed2U Inc. Services are not for emergency medical
                                    needs.&nbsp; If you have a medical emergency, call 9-1-1
                                    immediately.
                                </p>
                                <p>
                                    TeleMed2U Inc. grants you a limited, non-exclusive,
                                    non-sublicensable, revocable, non-transferable license to use
                                    the Platforms through remote connectivity in accordance with
                                    these Terms and Conditions. In order to use the Platforms, you
                                    will be asked to register an account and create login
                                    information, including without limitation, username and
                                    passwords. You must protect your login information that you use
                                    to access the Platforms and you must not disclose this
                                    information to anyone. You must immediately notify TeleMed2U
                                    Inc. of any unauthorized use of your user account or of any
                                    other breach of security that you become aware of involving and
                                    relating to the Platforms by sending an email to{' '}
                                    <a href="http://privacy@telemed2u.com">privacy@telemed2u.com</a>
                                    .
                                </p>
                                <h3>Termination</h3>
                                <p>
                                    Your access and use of the Websites and/or Platforms will be
                                    terminated if you violate these Terms and Conditions.&nbsp;
                                    TeleMed2U Inc. may, in its sole discretion, terminate your
                                    access to the Websites and/or Platforms, or any portion thereof,
                                    for any reason without prior notice.&nbsp;
                                </p>
                                <h3>TeleMed2U Inc.’s Role</h3>
                                <p>
                                    TeleMed2U Inc. is not an insurance provider. TeleMed2U Inc.'s
                                    role is limited to making information available to you and/or
                                    improve your access to telemedicine and medical services.
                                    TeleMed2U Inc. is independent from healthcare providers who will
                                    be providing such telemedicine services to you through the
                                    Platform and is not responsible for such healthcare providers'
                                    acts, omissions or for any content of the communications made by
                                    them. All of the Providers are independent of TeleMed2U Inc, and
                                    Providers use the Services to communicate with you. Any
                                    information or advice received from a Provider comes from the
                                    Provider, and not from TeleMed2U Inc. TeleMed2U Inc. does not
                                    engage in the practice of medicine or provide any other health
                                    services.
                                </p>
                                <h3>Warranty</h3>
                                <p>
                                    Access to the Website and the information contained therein is
                                    provided “as is'' and “as available” without any warranty of any
                                    kind, express or implied. TeleMed2U Inc. does not warrant the
                                    accuracy, completeness or timeliness of the materials on this
                                    website and to the fullest extent permissible pursuant to
                                    applicable law. TeleMed2U Inc. disclaims all warranties of any
                                    kind, either express or implied, including but not limited to
                                    any implied warranties of title, merchantability, fitness for a
                                    particular purpose or non-infringement, with respect to any
                                    information acquired through the website. TeleMed2U Inc. does
                                    not warrant that access to the website will be uninterrupted or
                                    error-free, or that defects, if any, will be corrected; nor does
                                    TeleMed2U Inc. make any representations about the accuracy,
                                    reliability, currency, quality, completeness, usefulness,
                                    performance, security, legality or suitability of the website or
                                    any of the information contained therein. You expressly agree
                                    that your use of the website and your reliance upon any of its
                                    contents is at your sole risk.
                                </p>
                                <h3>Your Representations</h3>
                                <p>
                                    By registering on the Platforms, you represent and warrant the
                                    following: (i) you are at least eighteen (18) years of age, (ii)
                                    you have the legal ability and authority to enter into these
                                    Terms and Conditions with TeleMed2U Inc., (iii) the information
                                    you have provided to TeleMed2U Inc. in your registration is
                                    accurate and complete, (iv) you will comply with any and all
                                    laws applicable to your use of the Websites and Platforms, (v)
                                    you will not interfere with a third party's use of the Websites
                                    and Platforms, (vi) you will not interfere with or disrupt
                                    TeleMed2U Inc.'s or its vendors' security measures, (vii) if any
                                    information you provide to TeleMed2U Inc. becomes inaccurate,
                                    incomplete or otherwise false or misleading, you will
                                    immediately notify TeleMed2U Inc., (viii) you are accessing the
                                    Websites and Platforms for yourself or a child under the age of
                                    eighteen (18) years of age for whom you are the legal guardian.
                                    You hereby certify that you are physically located in the State
                                    you have entered as your current location on the Services.
                                </p>
                                <h3>Prohibited Uses</h3>
                                <p>
                                    In using the Websites and Platforms, you agree not to use, or
                                    attempt to access or use, the Services to take any action that
                                    could harm us or any third party, interfere with the operation
                                    of the Websites and Platforms, in a manner that violates any
                                    laws.&nbsp; For example, and without limitation, you may not:
                                </p>
                                <ul role="list">
                                    <li>
                                        Send or otherwise transmit to or through the Websites and
                                        Platforms
                                        <br />
                                        any unlawful, infringing, harmful, harassing, defamatory,
                                        threatening, hateful or otherwise objectionable material of
                                        any kind, any material that can cause harm or delay to the
                                        Websites and Platforms or computers of any kind, and any
                                        unsolicited advertising, solicitation or promotional
                                        materials;
                                    </li>
                                    <li>Misrepresent your identity or association in any way;</li>
                                    <li>
                                        Restrict or inhibit any person from using the Websites and
                                        <br />
                                        Platforms, disclose personal information obtained from the
                                        Websites and Platforms or collect information about users of
                                        the Websites and Platforms;
                                    </li>
                                    <li>
                                        Reverse engineer, disassemble or decompile any section or
                                        technology
                                        <br />
                                        on the Websites and Platforms, or attempt to do any of the
                                        foregoing;
                                    </li>
                                    <li>
                                        Gain unauthorized access to the Websites and Platforms, to
                                        other
                                        <br />
                                        users' accounts, names, personally identifiable information
                                        or other information, or to other computers or websites
                                        connected or linked to the Websites and Platforms;
                                    </li>
                                    <li>
                                        Launch or use any automated system, including without
                                        limitation,
                                        <br />
                                        "robots," "spiders," or "offline readers," that access the
                                        Websites and Platforms in a manner that sends more request
                                        messages to our servers in a given period of time than a
                                        human can reasonably produce in the same period by using a
                                        conventional web browser;
                                    </li>
                                    <li>
                                        Send or otherwise transmit to or through the Websites and
                                        Platforms
                                        <br />
                                        chain letters, unsolicited messages, so-called "spamming" or
                                        "phishing" messages, or messages marketing or advertising
                                        goods and services;
                                    </li>
                                    <li>
                                        Post, transmit or otherwise make available any virus, worm,
                                        spyware or any other computer code, file or program that may
                                        or is intended to damage or hijack the operation of any
                                        hardware, software or telecommunications equipment;
                                    </li>
                                    <li>Violate any applicable laws or regulations in any way;</li>
                                    <li>
                                        Alter or modify any part of the content or services offered
                                        on or through the Websites and Platforms;
                                    </li>
                                    <li>
                                        Allow any other person to use the Platforms with your
                                        registration or login information;
                                    </li>
                                    <li>
                                        Breach or otherwise bypass TeleMed2U Inc.'s security or
                                        authentication measures; and
                                    </li>
                                    <li>
                                        Assist or grant any person(s) in engaging in any of the
                                        activities described above.
                                    </li>
                                </ul>
                                <h3>Intellectual Property Rights and Content</h3>
                                <p>
                                    TeleMed2U Inc. is the sole and exclusive owner of the Websites
                                    and Platforms, including any and all copyright, patent,
                                    trademark, trade secret and other ownership and intellectual
                                    property rights, in and to the Websites and Platforms and any
                                    related materials and documentation. No title or ownership of
                                    the Websites and Platforms or any portion thereof is transferred
                                    to you hereunder. TeleMed2U Inc. reserves all rights not
                                    expressly granted hereunder. You agree not to change or delete
                                    any copyright or proprietary notice related to materials
                                    downloaded from the Websites and/or Platforms.
                                </p>
                                <p>
                                    You may provide input, comments or suggestions regarding the
                                    Websites and Platforms or TeleMed2U Inc. Services ("Feedback").
                                    You acknowledge and agree that TeleMed2U Inc. may use any
                                    Feedback without any obligation to you and you hereby grant
                                    TeleMed2U Inc. a perpetual, irrevocable, royalty-free,
                                    transferable license not restricted to any geographical location
                                    to reproduce, display, perform, distribute, publish, modify,
                                    edit or otherwise use such Feedback as TeleMed2U Inc. may deem
                                    appropriate, without restriction, for any and all commercial
                                    and/or non-commercial purposes, in its sole discretion.
                                </p>
                                <h3>Indemnification</h3>
                                <p>
                                    You agree to indemnify, defend and hold us and our parents,
                                    subsidiaries, affiliates, licensors, suppliers, and their
                                    directors, officers, affiliates, subcontractors,
                                    employees,&nbsp; agents (each a TeleMed2U Inc. Party) and
                                    assigns harmless from and against any claims, actions or
                                    demands, losses, liabilities, damages, costs, expenses and
                                    settlements (including without limitation reasonable attorney
                                    and accounting fees), resulting from or alleged to result from,
                                    directly or indirectly, your (a) violation of these Terms and
                                    Conditions; (b) access to or use of the Platform and Websites;
                                    and (c) provision of other disclosure to TeleMed2U Inc. of any
                                    other information or data and the use of same by TeleMed2U Inc.
                                    or other TeleMed2U Inc. Party as contemplated hereunder.
                                </p>
                                <h3>Limitation of Liability</h3>
                                <p>
                                    IN NO EVENT SHALL TeleMed2U Inc. BE LIABLE FOR ANY DIRECT,
                                    INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL, EXEMPLARY, OR
                                    CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM
                                    ANY LOSS OF USE, LOSS OF PROFITS, LITIGATION, OR ANY OTHER
                                    PECUNIARY LOSS, WHETHER BASED ON BREACH OF CONTRACT, TORT
                                    (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, OR OTHERWISE, ARISING
                                    OUT OF OR IN ANY WAY CONNECTED WITH THE WEBSITES OR PLATFORMS OR
                                    THE PROVISION OF OR FAILURE TO MAKE AVAILABLE ANY SUCH PRODUCTS,
                                    GOODS, OR SERVICES, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
                                    DAMAGES.
                                </p>
                                <p>
                                    TeleMed2U Inc. shall not be liable for any failure to perform
                                    its obligations hereunder where the failure results from any
                                    cause beyond TeleMed2U Inc.'s reasonable control, including,
                                    without limitation, mechanical, electronic, or communications
                                    failure or degradation.
                                </p>
                                <p>
                                    The terms of these Terms and Conditions that limit liability
                                    express an informed voluntary allocation of risk; such
                                    allocation represents a material part of these Terms and
                                    Conditions. You agree that the limitations of liabilities set
                                    out in these Terms and Conditions are fair and reasonable in the
                                    circumstances.&nbsp; Because some jurisdictions do not allow the
                                    exclusion or limitation of incidental or consequential damages,
                                    TeleMed2U’s liability in such jurisdictions shall be limited to
                                    the maximum extent permitted by law.&nbsp;{' '}
                                </p>
                                <h4>Disclaimer of Warranties</h4>
                                <p>
                                    The Websites and Platforms are provided on an "as is" and "as
                                    available" basis and without warranties of any kind, either
                                    express or implied. To the fullest extent permissible pursuant
                                    to applicable law, TeleMed2U Inc. disclaims all representations,
                                    warranties, and conditions, express or implied, including, but
                                    not limited to, implied condition or warranties of
                                    merchantability and fitness for a particular purpose. TeleMed2U
                                    Inc. does not warrant that the Websites and Platforms will be
                                    uninterrupted or error-free, that defects will be corrected or
                                    that the Websites and Platforms or the server that makes it
                                    available are free of viruses or other harmful components.
                                </p>
                                <p>
                                    TeleMed2U Inc. makes no guarantees, and disclaims any implied
                                    warranty or representation about its accuracy, relevance,
                                    timeliness, completeness, or appropriateness of any content
                                    posted on the Website and Platforms for a particular
                                    purpose.&nbsp; TeleMed2U Inc. assumes no liability arising from
                                    or relating to the delay, failure, interruption, omission or
                                    corruption of any data or other information transmitted in
                                    connection with use of the Websites and/or Platforms.
                                </p>
                                <p>
                                    Applicable law may not allow the exclusion of implied
                                    warranties, so the above exclusion may not apply to you.
                                </p>
                                <h4>Direct to Consumer - Psychiatry Services</h4>
                                <p>
                                    If you miss an appointment, we can work with you to get you
                                    rescheduled for another available slot. However, if you miss
                                    more than two consecutive appointments, we will have to cancel
                                    your subscription without a refund as this goes directly to the
                                    providers for the time that was set apart for you.
                                </p>
                                <h3>Minors</h3>
                                <p>
                                    The Websites and Platforms are not intended for use by anyone
                                    under the age of 18. TeleMed2U Inc. does not seek through the
                                    Websites or Platforms to gather personal information from or
                                    about persons under the age of 18 without the consent of a
                                    parent or guardian, unless state law permits. TeleMed2U
                                    considers anyone below the age of 18 as a “minor” and delivers
                                    various services to minors. A patient account for minors must be
                                    created and managed by a parent, a legal guardian, or an
                                    authorized representative unless state law permits otherwise.
                                </p>
                                <h3>Choice of Law and Jurisdiction</h3>
                                <p>
                                    These Terms and Conditions are governed by the laws of
                                    California. The parties irrevocably submit to the exclusive
                                    jurisdiction of the courts of the State of California in respect
                                    of all matters and disputes arising hereunder, and waive any
                                    defense of lack of personal jurisdiction in that jurisdiction.
                                    These Terms and Conditions are not governed by the{' '}
                                    <em>
                                        United Nations Convention on Contracts for the International
                                        Sale of Goods
                                    </em>
                                    , the application of which is hereby expressly excluded. If any
                                    provision of these Terms and Conditions is found to be invalid
                                    by any court having competent jurisdiction, the invalidity of
                                    such provision shall not affect the validity of the remaining
                                    provisions of these Terms and Conditions, which shall remain in
                                    full force and effect.
                                </p>
                                <h3>Personal Information and Privacy Policy</h3>
                                <p>
                                    In these Terms and Conditions, "personal information" means any
                                    information about an identifiable individual, such as your name,
                                    email address, mailing address, gender, date of birth, any
                                    personal or protected health information, or, any data about you
                                    that you elect to provide electronically through the Websites
                                    and Platforms and any other information that identifies who you
                                    are. Personal Information will be used by TeleMed2U Inc. solely
                                    in accordance with these Terms and Conditions, and TeleMed2U
                                    Inc.’s Privacy Policy.
                                </p>
                                <h3>Waiver</h3>
                                <p>
                                    No delay or omission by TeleMed2U Inc. to exercise any right or
                                    power it has under these Terms and Conditions or to object to
                                    the failure of any covenant of you to be performed in a timely
                                    and complete manner, shall compromise any such right or power or
                                    be construed as a waiver of any ensuing breach or any other
                                    covenant. Any waivers by TeleMed2U Inc. must be in writing and
                                    signed by an authorized representative of TeleMed2U Inc..
                                </p>
                                <h3>Contact Us</h3>
                                <p>
                                    If you have any questions about the Terms and Conditions, or
                                    need to provide notice to, or communicate with, TeleMed2U Inc.
                                    under the Terms and Conditions, please contact TeleMed2U Inc.
                                    using the following contact details:
                                    <br />
                                    <br />
                                    3400 Douglas Blvd.
                                    <br />
                                    Suite 225
                                    <br />
                                    Roseville, CA 95661
                                    <br />
                                    Phone: (855) 446-8628
                                </p>
                                <p>
                                    <a href="http://privacy@telemed2u.com">privacy@telemed2u.com</a>
                                </p>
                                <p>
                                    TeleMed2U Inc. may provide notices or communications to you on
                                    the Websites and/or Platforms and you agree that such notices
                                    shall constitute notice to you whether or not you actually
                                    access the notice.
                                </p>
                                <p>
                                    Finally, thank you so much for visiting our website and using
                                    our services. We look forward to helping you.
                                </p>
                                <p>The TeleMed2U Team</p>
                                <p>‍</p>
                            </div>
                        </TabPanel>
                        <TabPanel
                            value={currentTab}
                            index={1}
                            tabContentRef={(ref) => (tabContentRefs.current[1] = ref)}
                            setIsScroll={setIsScroll}>
                            <div>
                                <h3 style={{ textAlign: 'center' }}>
                                    Informed Consent for Medical Services
                                </h3>
                                <ul role="list">
                                    <li>
                                        I consent to participate in tele mental health services with
                                        a TeleMed2U provider as part of my psychotherapy. I
                                        understand that tele mental health is the practice of
                                        delivering clinical health care services via
                                        technology-assisted media or other electronic means between
                                        a practitioner and a client who are located in two different
                                        locations.
                                        <br />‍
                                    </li>
                                    <li>
                                        {' '}
                                        <strong>
                                            I understand the nature of counseling:
                                            <br />‍
                                        </strong>
                                        The type and extent of services that I will receive will be
                                        determined following an initial assessment and through
                                        discussion with me. I understand that there maybe both
                                        benefits and risks associate with participation in
                                        counseling. Counseling may improve the ability to relate to
                                        others, provide a clearer understanding of self, values, and
                                        goals. I understand that counseling may also lead to
                                        unanticipated feelings of change, which might have
                                        unexpected impact on me and my relationships.
                                    </li>
                                    <li>
                                        <strong>
                                            I understand the following with respect to tele mental
                                            health:
                                            <br />
                                            <br />
                                        </strong>
                                        1.&nbsp;I understand that I have the right to withdraw
                                        consent at any time without affecting my right to future
                                        care, services, or program benefits to which I would
                                        otherwise be entitled.
                                        <br />
                                        <br />
                                        2.&nbsp;I understand that there are risks, benefits, and
                                        consequences associated with tele mental health, including
                                        but not limited to, disruption of transmission by technology
                                        failures, interruption and/or breaches of confidentiality by
                                        unauthorized persons, and/or limited ability to respond to
                                        emergencies.
                                        <br />
                                        <br />
                                        3.&nbsp;I understand that there will be no recording of any
                                        of the online sessions by either party. All information
                                        disclosed within sessions and written records pertaining to
                                        those sessions are confidential and may not be disclosed to
                                        anyone without written authorization, except where the
                                        disclosure is permitted and/or required by law.
                                        <br />
                                        <br />
                                        4.&nbsp;I understand that the privacy laws that protect the
                                        confidentiality of my protected health information (PHI)
                                        also apply to tele mental health unless an exception to
                                        confidentiality applies (i.e. mandatory reporting of child,
                                        elder, or vulnerable adult abuse; danger to self or others;
                                        I raise mental/emotional health as an issue in a legal
                                        proceeding).
                                        <br />
                                        <br />
                                        5.&nbsp;I understand that if I am having suicidal or
                                        homicidal thoughts, actively experiencing psychotic symptoms
                                        or experiencing a mental health crisis that cannot be
                                        resolved remotely, it may be determined that tele mental
                                        health services are not appropriate and a higher level of
                                        care is required.
                                        <br />
                                        <br />
                                        6.&nbsp;I understand that if I am under the age of 18 that
                                        the privacy laws that protect confidentiality still apply
                                        and the provider is mandated to break confidentiality in
                                        situations detailed above.
                                        <br />
                                        <br />
                                        7. I understand that my therapist may need to contact my
                                        emergency contact and/or appropriate authorities in case of
                                        an emergency.
                                        <br />‍
                                    </li>
                                    <li>
                                        {' '}
                                        <strong>
                                            Why do I need to sign this if I am not getting therapy
                                            from you?
                                            <br />‍
                                        </strong>
                                        Although we might not be delivering therapy to you right
                                        now, having the consent on file is required should you and
                                        your provider agree to include therapy as part of your
                                        personalized treatment plan in the future and the therapy is
                                        delivered by one of our therapists.
                                    </li>
                                </ul>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                            </div>
                        </TabPanel>
                        <TabPanel
                            value={currentTab}
                            index={2}
                            tabContentRef={(ref) => (tabContentRefs.current[2] = ref)}
                            setIsScroll={setIsScroll}>
                            <div>
                                <h3 style={{ textAlign: 'center' }}>Telehealth Consent</h3>
                                <p>
                                    These Terms of Use (“Terms”) govern your use of the TeleMed2U,
                                    Inc. (“we,” “us,” or “our”) website and any other website or
                                    online service that TeleMed2U, Inc. operates and that links to
                                    these Terms (collectively, the “Services”).
                                </p>
                                <p>
                                    <strong>
                                        Please review these Terms carefully before using the
                                        Services.{' '}
                                    </strong>
                                    We may change these Terms or modify any features of the Services
                                    at any time.&nbsp;{' '}
                                    <strong>
                                        You accept the Terms by using the Services, and you accept
                                        any changes to the Terms by continuing to use the Services
                                        after we post the changes.
                                    </strong>
                                </p>
                                <p>
                                    The Service respects and upholds patient confidentiality with
                                    respect to protected health information as outlined by the
                                    Health Insurance Portability and Accountability Act (HIPAA),
                                    and, subject to HIPAA regulations, will obtain express patient
                                    consent prior to sharing any patient-identifiable information to
                                    a third party for purposes other than treatment, payment or
                                    health care operations.&nbsp;&nbsp;
                                </p>
                                <p>
                                    In addition, by clicking the “AGREE” button you are authorizing
                                    Providers to release your contact information to Telemedicine
                                    Group PC solely in order for Telemedicine Group PC to provide
                                    you with information about additional clinical services or
                                    general wellness.&nbsp; You may opt out of receiving such
                                    information by contacting us at Privacy@TeleMed2U.com. When
                                    using the Service you may be asked if you would like to share
                                    certain Protected Health Information (PHI) collected by third
                                    party platforms with Telemedicine Group PC.&nbsp; By clicking on
                                    “AGREE” you are authorizing Telemedicine Group PC to collect and
                                    Provider to utilize such PHI.
                                </p>
                                <p>We are providing this information on behalf of Providers:</p>
                                <p>
                                    Telemedicine involves the use of audio, video, or other
                                    electronic communication to enable health care providers at
                                    sites remote from patients to provide medical consultative
                                    services, therapy, or treatment remotely. Video-conferencing
                                    technology will be used by your provider to facilitate the
                                    telehealth session. With your consent, the session may be
                                    recorded for quality review, operations, training, research, and
                                    safety purposes. The information may be used for diagnosis,
                                    therapy, follow-up and/or education, and may include live
                                    two-way audio, video, and other materials.
                                </p>
                                <h3>Telemedicine Benefits:</h3>
                                <ul role="list">
                                    <li>
                                        Improved access to health care providers by allowing a
                                        patient to consult a provider from his or her home or
                                        office.
                                    </li>
                                    <li>
                                        Improved efficiency for access to medical evaluation and
                                        management.
                                    </li>
                                </ul>
                                <h3>Telemedicine Risks:</h3>
                                <p>
                                    As with any medical procedure, there are potential risks
                                    associated with the use of telemedicine. &nbsp; These risks may
                                    include, without limitation, the following:
                                </p>
                                <ul role="list">
                                    <li>
                                        Technical problems:&nbsp; digital issues such as video
                                        quality, sound quality, or connectivity issues that may
                                        require an encounter to be rescheduled or referral to an in
                                        person appointment.
                                    </li>
                                    <li>
                                        Although measures are in place to prevent a breach of
                                        privacy, security protocols could fail, causing a breach of
                                        privacy of personal medical information.
                                    </li>
                                </ul>
                                <p>‍</p>
                                <p>
                                    By accepting these Terms, you acknowledge that you understand
                                    and agree with the following:
                                </p>
                                <ol role="list">
                                    <li>
                                        I understand that a video consultation will not be the same
                                        as an in-person visit with a healthcare provider due to the
                                        fact that I will not be in the same room as my
                                        provider.&nbsp; Telemedicine may involve electronic
                                        communication of my personal medical information to medical
                                        practitioners who may be located in other areas, including
                                        out of state.
                                    </li>
                                    <li>
                                        I may ask my provider questions regarding the benefits and
                                        risks of telehealth and ensure those questions are answered
                                        to my satisfaction prior to receiving services.
                                    </li>
                                    <li>
                                        I understand that the laws that protect privacy and the
                                        confidentiality of medical information also apply to
                                        telemedicine; I have reviewed the HIPAA Notice.
                                    </li>
                                    <li>
                                        I understand that I may expect the anticipated benefits from
                                        the use of telemedicine in my care, but that no results can
                                        be guaranteed or assured.
                                    </li>
                                    <li>
                                        I understand that my healthcare information may be shared
                                        with others (including health care providers and health
                                        insurers) for treatment, payment, and healthcare operations
                                        purposes.&nbsp;&nbsp;
                                    </li>
                                    <li>
                                        I understand that I have the option to refuse the delivery
                                        of health care services by telehealth at any time without
                                        affecting my right to future care or treatment, and without
                                        risking the loss or withdrawal of any benefits to which I
                                        would otherwise be entitled.
                                    </li>
                                    <li>
                                        You may receive services from network providers on an
                                        in-person basis or via telehealth, if available. Contact
                                        your primary care provider, treating specialist, facility,
                                        or other health professional to learn whether telehealth is
                                        an option. Network telehealth and in-person services are
                                        subject to the same timeliness and geographic access
                                        standards. If your plan has out-of-network benefits, they
                                        are subject to your plan’s cost sharing obligations and
                                        balance billing protections.
                                    </li>
                                    <li>
                                        You may receive services from network providers on an
                                        in-person basis or via telehealth, if available. Contact
                                        your primary care provider, treating specialist, facility,
                                        or other health professional to learn more. Network
                                        telehealth and in-person services are subject to the same
                                        timeliness and geographic access standards. Although your
                                        plan has [no/limited] out-of-network benefits, if you use
                                        out-of-network services, you are subject to the plan’s
                                        cost-sharing obligation and balance billing protections.
                                    </li>
                                    <li>
                                        You may receive services from providers on an in-person
                                        basis or via telehealth, if available. Contact your
                                        provider, treating specialist, facility, or other health
                                        professional to learn more. Telehealth and in-person
                                        services are subject to the same timeliness and geographic
                                        access standards. You are subject to your Medicare
                                        Supplement plan’s cost-sharing obligations and balance
                                        billing protections.
                                    </li>
                                    <li>
                                        You have the right to access your medical records. Records
                                        of the care you receive from third-party corporate
                                        telehealth providers will be shared with your primary care
                                        provider (PCP). Your record will be made accessible in an
                                        electronic patient record system or provided in a different
                                        manner unless you opt out. Costs incurred for services
                                        received through the third-party corporate telehealth
                                        provider are available at in-network cost-sharing rates.
                                        Out-of-pocket costs shall accrue to any applicable
                                        deductible or out-of-pocket maximum.{' '}
                                    </li>
                                    <li>
                                        If you do not want a third-party telehealth provider to
                                        share information about the care you receive with your
                                        primary care provider, call the number on the back of your
                                        medical insurance card or contact us (your telehealth
                                        provider) at{' '}
                                        <a href="http://privacy@telemed2u.com">
                                            privacy@telemed2u.com
                                        </a>{' '}
                                        to opt out.
                                    </li>
                                    <li>
                                        I further understand that my healthcare information may be
                                        shared in the following circumstances:
                                    </li>
                                </ol>
                                <ul role="list">
                                    <li>When a valid court order is issued for medical records.</li>
                                    <li>
                                        Reporting suspected abuse, neglect, or domestic violence.
                                    </li>
                                    <li>
                                        Preventing or reducing a serious threat to anyone’s health
                                        or safety.
                                    </li>
                                </ul>
                                <h3>
                                    Patient Consent to the Use of Telemedicine
                                    <br />
                                </h3>
                                <p>
                                    I have read and understand the information provided above, and
                                    understand the risks and benefits of Services. By accepting
                                    these Terms, I hereby give consent to telehealth as an
                                    acceptable form of delivering healthcare services to me and that
                                    this consent will cover any and all of my sessions using
                                    telehealth.
                                    <br />
                                </p>
                            </div>
                        </TabPanel>
                        <TabPanel
                            value={currentTab}
                            index={3}
                            tabContentRef={(ref) => (tabContentRefs.current[3] = ref)}
                            setIsScroll={setIsScroll}>
                            <div>
                                <h3 style={{ textAlign: 'center' }}>Privacy Policy</h3>
                                <h4>
                                    TeleMed2U Inc. is deeply committed to protecting the privacy and
                                    security of our customers’ data.&nbsp;
                                </h4>
                                <p>
                                    This statement sets forth TeleMed2U’s Privacy Policy (“Privacy
                                    Policy”) and describes the practices that we will follow with
                                    respect to the privacy of the information of users of this site
                                    and our mobile applications. TeleMed2U Inc. owns and operates
                                    various publicly available websites, including without
                                    limitation, www.telemed2u.com, www.specialtyaustin.com,
                                    www.bbraustin.com, www.inlandendocrine.com, and
                                    www.medzino.com/us/ (collectively, "Websites"). By visiting any
                                    of our “Websites or Platforms'', you acknowledge that you accept
                                    the practices and policies outlined in this Privacy Policy.
                                    “You” “your”, or similar terms refer to you as a user of the
                                    “Services”. This policy applies to the information we collect:
                                </p>
                                <ul role="list">
                                    <li>through the Services; and&nbsp;</li>
                                    <li>
                                        via email, text, or other electronic messages between you
                                        and any employee or agent of TeleMed2U.
                                    </li>
                                </ul>
                                <p>
                                    This policy does not apply to the actions of any company or
                                    entity that we do not control and to individuals who we do not
                                    directly employ or manage.&nbsp;
                                </p>
                                <p>
                                    As used in this Privacy Policy, the terms “using” and
                                    “processing” information may include, amongst other things,
                                    subjecting such information to analysis, using cookies, and
                                    managing information in a number of ways, including but not
                                    limited to: collection, storing, evaluating, modifying,
                                    deleting, using, combining, and/or disclosing.&nbsp;
                                </p>
                                <p>
                                    Some of the information we collect may constitute protected
                                    health information (“PHI”) under the Health Insurance
                                    Portability and Accountability Act of 1996 (“HIPAA”), as amended
                                    from time to time. To the extent other state or local privacy
                                    and data protection laws apply to your data, we will comply with
                                    those requirements as well. Should you have any questions about
                                    this policy or our practices, please send an email to{' '}
                                    <a href="http://privacy@telemed2u.com">privacy@telemed2u.com</a>
                                    .
                                </p>
                                <h4>
                                    What Information We Collect
                                    <br />
                                </h4>
                                <p>
                                    TeleMed2U only collects other personal information about you
                                    through the Services only when you voluntarily provide it to us.
                                    If you choose to go through our screening process and/or
                                    register for the Services we ask you to provide personal
                                    information, including but not limited to: your name, address,
                                    telephone number and/or email address, height, weight, and
                                    health information in connection with the use of our products or
                                    services. We also collect information that will allow you to
                                    establish a username and password. You may choose not to provide
                                    us with certain information, but that may result in our
                                    inability to provide you access to the Services.
                                </p>
                                <h4>What Information We Disclose</h4>
                                <p>
                                    By using the Services you consent to and authorize TeleMed2U and
                                    its affiliates to disclose your eligibility for and
                                    participation in the Services (i.e. that you meet the enrollment
                                    criteria for the Services and that you have elected at your own
                                    discretion to participate).In particular, TeleMed2U and any of
                                    its affiliates may be able to access the information you provide
                                    to us for the purpose of delivering the care requested by you.
                                </p>
                                <p>
                                    No mobile information will be shared with third
                                    parties/affiliates for marketing/promotional purposes; all the
                                    above categories exclude text messaging originator opt-in data
                                    and consent; this information will not be shared with any third
                                    parties. For any further inquiries, refer to TeleMed2U{' '}
                                    <a href="https://www.telemed2u.com/sms-policy">
                                        SMS Terms &amp; Conditions
                                    </a>
                                </p>
                                <h3>
                                    How We Use Personal Information That We Collect Online
                                    <br />
                                </h3>
                                <p>
                                    <strong>(a) Internal Uses</strong> - We may use information we
                                    collect about you to:
                                </p>
                                <ul role="list">
                                    <li>
                                        Provide you with access to particular tools and services;
                                    </li>
                                    <li>
                                        Respond to your inquiries and send you administrative
                                        communications;
                                    </li>
                                    <li>Obtain your feedback on our websites and our offerings;</li>
                                    <li>Statistically analyze user behavior and activity;</li>
                                    <li>Provide you with more relevant content;</li>
                                    <li>Conduct research and measurement activities;</li>
                                    <li>
                                        Send you personalized emails or secure electronic messages
                                        pertaining to your health information; or
                                    </li>
                                    <li>
                                        Contact you about the products and services that we offer.
                                    </li>
                                </ul>
                                <p>
                                    We may combine personal and non-personal information collected
                                    by TeleMed2U about you, and may combine this information with
                                    information provided by external sources.&nbsp;&nbsp;
                                </p>
                                <p>
                                    We limit TeleMed2U’s employees’ and contractors’ access to
                                    personal information. Only those employees and contractors with
                                    a business reason to know have access to this information, and
                                    then may only access or use the minimum necessary for the task
                                    at hand. We educate our employees about the importance of
                                    maintaining confidentiality of user information.
                                </p>
                                <p>
                                    <strong>(b) Special Circumstances</strong>
                                    <br />
                                    The following categories describe unique circumstances in which
                                    TeleMed2U may use or disclose your PHI:
                                </p>
                                <ol role="list">
                                    <li>
                                        Public Health Activities. TeleMed2U may disclose your PHI to
                                        public health authorities or other governmental authorities
                                        for purposes including preventing and controlling disease,
                                        reporting child abuse or neglect, reporting domestic
                                        violence and reporting to the Food and Drug Administration
                                        regarding the quality, safety and effectiveness of a
                                        regulated product or activity. TeleMed2U may, in certain
                                        circumstances disclose PHI to persons who have been exposed
                                        to a communicable disease or may otherwise be at risk of
                                        contracting or spreading a disease or condition.
                                    </li>
                                    <li>
                                        Health Oversight Activities. TeleMed2U may disclose your PHI
                                        to a health oversight agency for authorized activities such
                                        as audits, investigations, inspections, licensing and
                                        disciplinary actions relating to the healthcare system or
                                        government benefit programs.
                                    </li>
                                    <li>
                                        Judicial and Administrative Proceedings. TeleMed2U may
                                        disclose your PHI, in certain circumstances, as permitted by
                                        applicable law, in response to an order from a court or
                                        administrative agency, or in response to a subpoena or
                                        discovery request.
                                    </li>
                                    <li>
                                        Law Enforcement. TeleMed2U may, under certain circumstances,
                                        disclose your PHI to a law enforcement official, such as for
                                        purposes of identifying or locating a suspect, fugitive,
                                        material witness or missing person.
                                    </li>
                                    <li>
                                        Decedents. TeleMed2U may, under certain circumstances,
                                        disclose PHI to coroners, medical examiners and funeral
                                        directors for purposes such as identification, determining
                                        the cause of death and fulfilling duties relating to
                                        decedents.
                                    </li>
                                    <li>
                                        Organ Procurement. TeleMed2U may, under certain
                                        circumstances, use or disclose PHI for the purposes of organ
                                        donation and transplantation.
                                    </li>
                                    <li>
                                        Threat to Health or Safety. TeleMed2U may, under certain
                                        circumstances, use or disclose PHI if necessary to prevent
                                        or lessen a serious and imminent threat to the health or
                                        safety of a person or the public.
                                    </li>
                                </ol>
                                <h4>
                                    Cookie Placement
                                    <br />
                                </h4>
                                <p>
                                    Certain TeleMed2U websites, like many other commercial websites,
                                    may use a standard technology called “cookies” to collect
                                    information about how our website is used. Cookies were designed
                                    to help a website operator determine that a particular user had
                                    visited the site previously and thus save and remember any
                                    preferences that may have been set. We may use cookies to
                                    temporarily keep track of information about your current web
                                    browsing session which will be discarded as soon as you log out
                                    or close your web browser. This information also allows us to
                                    statistically monitor how many people are using our website and
                                    for what purpose. We may also make use of “persistent” or
                                    “memory based” cookies, which remain on your computer’s hard
                                    drive until you delete them. Although you have the ability to
                                    modify your browser to either accept all cookies, notify you
                                    when a cookie is sent, or reject all cookies, it may not be
                                    possible to utilize our services if you reject all cookies.
                                </p>
                                <h4>How can you help protect your information?</h4>
                                <p>
                                    If you are using a TeleMed2U website or mobile application for
                                    which you registered and choose a password, we recommend that
                                    you do not share your password to anyone. We will never ask you
                                    for your password in an unsolicited phone call or in an
                                    unsolicited email. Always remember to sign out of the TeleMed2U
                                    website and close your browser window when you have finished
                                    using the Services. This is to ensure that others cannot access
                                    your personal information and correspondence to us if others
                                    have access to your computer or mobile device.
                                </p>
                                <h4>Children</h4>
                                <p>
                                    We are committed to protecting the privacy of children. The
                                    website is intended to be used by any person who is at least
                                    eighteen (18) years of age. We offer a range of services to
                                    children, however an account must be created for them by a
                                    parent or a legal guardian.&nbsp;
                                </p>
                                <h4>Your California Privacy Rights</h4>
                                <p>
                                    If you are a California resident, California law provides you
                                    with additional rights regarding our collection, use, and
                                    disclosure of your personal information. To learn about the
                                    personal information we collect, use, and disclose along with
                                    information regarding your additional California privacy rights,
                                    to learn more please visit{' '}
                                    <a href="https://oag.ca.gov/privacy/ccpa">
                                        https://oag.ca.gov/privacy/ccpa
                                    </a>
                                    .&nbsp;
                                </p>
                                <h4>Changes in Our Privacy Policy</h4>
                                <p>
                                    It is our goal to use personal information and personal health
                                    information in the manner described in the privacy policy that
                                    is in effect when your information is collected. We do, however,
                                    reserve the right to change the terms of this privacy policy at
                                    any time and will post revisions to our site.
                                </p>
                                <h3>How to Contact Us</h3>
                                <p>
                                    If you have any questions about this Privacy Statement, please
                                    contact us at{' '}
                                    <a href="mailto:privacy@telemed2u.com">privacy@telemed2u.com</a>
                                    .&nbsp;
                                    <br />
                                </p>
                            </div>
                        </TabPanel>
                        <TabPanel
                            value={currentTab}
                            index={4}
                            tabContentRef={(ref) => (tabContentRefs.current[4] = ref)}
                            setIsScroll={setIsScroll}>
                            <div>
                                <h3 style={{ textAlign: 'center' }}>Notice of Privacy Practices</h3>
                                <p>
                                    This Notice of Privacy Practices (Notice) is being provided to
                                    you on behalf of TeleMed2U Inc., and the Telemedicine Group
                                    Professional Corporations (collectively referred to herein as
                                    “we” or “our”) and describes how your medical information may be
                                    used and disclosed and how you can access this
                                    information.&nbsp;
                                </p>
                                <h3>Your Rights Regarding Protected Health Information (PHI)</h3>
                                <h4>
                                    1. An electronic or paper copy of your medical record
                                    <strong>
                                        <br />
                                    </strong>
                                </h4>
                                <ul role="list">
                                    <li>
                                        You can ask to see or get an electronic or paper copy of
                                        your medical record and PHI. Most PHI is available in your
                                        Member Portal; however, you may also request the records in
                                        writing.&nbsp; Mail your written request to:&nbsp;
                                        TeleMed2U, Inc., Attn:&nbsp; Records Request,{' '}
                                        <a href="https://goo.gl/maps/ZVTQtBhRAdjRDM7q8">
                                            3400 Douglas Blvd., Suite 225, Roseville, CA 95661
                                        </a>{' '}
                                        or by sending an email to privacy@telemed2u.com.
                                    </li>
                                    <li>
                                        TeleMed2U, Inc. will provide your health information,
                                        usually within 30 days of your request. A fee may be imposed
                                        for mailing the records as well as for the labor involved in
                                        producing the records.&nbsp;&nbsp;
                                    </li>
                                </ul>
                                <h4>2. Changes in your medical record</h4>
                                <ul role="list">
                                    <li>
                                        You can ask us to change or correct PHI that you think is
                                        incorrect or incomplete.&nbsp; All requests must be
                                        submitted in writing with a reason to support the changes
                                        requested.&nbsp; TeleMed2U may deny this request, and any
                                        denial will be sent a reply in writing.&nbsp; Mail your
                                        written request to:&nbsp; TeleMed2U, Inc., Attn:&nbsp;
                                        Records Request,{' '}
                                        <a href="https://goo.gl/maps/ZVTQtBhRAdjRDM7q8">
                                            3400 Douglas Blvd., Suite 225, Roseville, CA 95661
                                        </a>{' '}
                                        or by sending an email to privacy@telemed2u.com.
                                    </li>
                                </ul>
                                <h4>
                                    3. Request confidential communications
                                    <br />
                                </h4>
                                <ul role="list">
                                    <li>
                                        You can request TeleMed2U to contact you in a specific way
                                        or to send mail to a different address.&nbsp; We will
                                        accommodate all reasonable requests.&nbsp; This request for
                                        alternative communication must be made in writing to:&nbsp;{' '}
                                        <a href="mailto:privacy@telemed2u.com">
                                            privacy@telemed2u.com
                                        </a>
                                        .&nbsp;
                                    </li>
                                </ul>
                                <h4>4. Request to restrict sharing information</h4>
                                <ul role="list">
                                    <li>
                                        You have the right to request TeleMed2U, Inc. not to use or
                                        share PHI for treatment, payment, or our operations. If
                                        restricting information sharing will affect your care,
                                        TeleMed2U may decline the request.&nbsp; If you pay for a
                                        service or health care item out-of-pocket in full, you can
                                        ask us not to share that information for the purpose of
                                        payment or our operations with your health insurer.&nbsp;
                                    </li>
                                    <li>
                                        You also have the right to request that TeleMed2U, Inc.
                                        restrict its disclosures of PHI to only certain individuals
                                        involved in your care or the payment of your care. However,
                                        if TeleMed2U, Inc. agrees to comply with your request, it
                                        will be bound by such agreement, except when otherwise
                                        required by law or in the event of an emergency.&nbsp;
                                    </li>
                                    <li>
                                        You must submit your request in writing to TeleMed2U, Inc.,
                                        and TeleMed2U, Inc. is not required to comply with your
                                        request. Mail your written request to:&nbsp; TeleMed2U,
                                        Inc., Attn:&nbsp; Records Request,{' '}
                                        <a href="https://goo.gl/maps/ZVTQtBhRAdjRDM7q8">
                                            3400 Douglas Blvd., Suite 225, Roseville, CA 95661
                                        </a>{' '}
                                        or by sending an email to privacy@telemed2u.com.
                                    </li>
                                    <li>
                                        TeleMed2U is a Participant in Manifest MedEx (MX), a Health
                                        Information Exchange that facilitates the electronic sharing
                                        of health information between healthcare providers to
                                        support better-informed, safer healthcare.You may choose not
                                        to have your health information shared through MX by
                                        opting-out. However, doing so means MX will not make your
                                        health information available to any healthcare providers,
                                        even in circumstances of emergency. If you would like to
                                        opt-out of MX, please complete and submit the online opt-out
                                        form at{' '}
                                        <a
                                            href="https://www.manifestmedex.org/opt-out"
                                            target="_blank">
                                            https://www.manifestmedex.org/opt-out
                                        </a>{' '}
                                        or call 1 (800) 490-7617.
                                    </li>
                                    <li>
                                        Opt-out provisions are not applicable to Patient Data which
                                        providers or health plans share to support authorization of
                                        services to patients, where those patients have already been
                                        informed of such sharing by a provider or health plan Notice
                                        of Privacy Practices.
                                    </li>
                                </ul>
                                <h4>
                                    5. Disclosures Request
                                    <br />
                                </h4>
                                <ul role="list">
                                    <li>
                                        You may request an account of the times your health
                                        information has been shared for six years prior to the date
                                        you ask, who it was shared with, and why it was whared.
                                    </li>
                                    <li>
                                        TeleMed2U, Inc. will include all the disclosures except for
                                        those about treatment, payment, and health care operations,
                                        and certain other disclosures (such disclosures at your
                                        request). Your first accounting will be free of charge.
                                        However, TeleMed2U may charge you for the costs involved in
                                        fulfilling any additional request made within a period of 12
                                        months. To make a request for an accounting of disclosures,
                                        mail your written request to:&nbsp; TeleMed2U, Inc.,
                                        Attn:&nbsp; Records Request,{' '}
                                        <a href="https://goo.gl/maps/ZVTQtBhRAdjRDM7q8">
                                            3400 Douglas Blvd., Suite 225, Roseville, CA 95661
                                        </a>{' '}
                                        or by sending an email to privacy@telemed2u.com.
                                    </li>
                                </ul>
                                <h4>
                                    6. Request a written copy of this privacy notice
                                    <br />
                                </h4>
                                <ul role="list">
                                    <li>
                                        You can ask for a paper copy of this notice at any time,
                                        even if you have agreed to receive the notice
                                        electronically. To obtain a paper copy of this notice,
                                        please contact the Privacy Officer by writing to: TeleMed2U,
                                        Inc., Attn:&nbsp; Privacy Officer,{' '}
                                        <a href="https://goo.gl/maps/ZVTQtBhRAdjRDM7q8">
                                            3400 Douglas Blvd., Suite 225, Roseville, CA 95661
                                        </a>{' '}
                                        or by sending an email to privacy@telemed2u.com.
                                    </li>
                                </ul>
                                <h4>7. Choose someone to act for you</h4>
                                <ul role="list">
                                    <li>
                                        If you designated someone as a medical power of attorney or
                                        if someone is your legal guardian, that person can exercise
                                        your rights and make choices about your health information.
                                    </li>
                                    <li>
                                        TeleMed2U, Inc. will verify the person has this authority
                                        and can act for you before any actions are taken.
                                    </li>
                                </ul>
                                <h4>
                                    8. File a complaint if you feel your rights are violated
                                    <br />
                                </h4>
                                <ul role="list">
                                    <li>
                                        You may file a complaint if you feel your rights have been
                                        violated by contacting us at TeleMed2U, Inc. You must submit
                                        a statement in writing to: TeleMed2U, Inc., Attn:&nbsp;
                                        Privacy Officer,{' '}
                                        <a href="https://goo.gl/maps/ZVTQtBhRAdjRDM7q8">
                                            3400 Douglas Blvd., Suite 225, Roseville, CA 95661
                                        </a>{' '}
                                        or by sending an email to{' '}
                                        <a href="mailto:privacy@telemed2u.com">
                                            privacy@telemed2u.com
                                        </a>
                                        .&nbsp; TeleMed2U, Inc. will not retaliate against you for
                                        filing a complaint.
                                    </li>
                                    <li>
                                        If you feel your privacy rights have been violated, you may
                                        submit a complaint with the U.S. Department of Health and
                                        Human Services Office for Civil Rights by sending a letter
                                        to 200 Independence Avenue, S.W., Washington, D.C. 20201,
                                        calling 1-877-696-6775, or visiting
                                        www.hhs.gov/ocr/privacy/hipaa/complaints/.
                                    </li>
                                </ul>
                                <h3>Uses and Disclosures of PHI</h3>
                                <p>
                                    TeleMed2U, Inc. may use or disclose health information in the
                                    following ways:
                                </p>
                                <ul role="list">
                                    <li>
                                        <strong>Treatment</strong>
                                        <br />
                                        TeleMed2U, Inc. can use your PHI and share it with other
                                        professionals who are involved in your care.
                                    </li>
                                    <li>
                                        <strong>Organizational Operations</strong>
                                        <br />
                                        TeleMed2U, Inc. can use and share your health information to
                                        run our practice, improve your care, and contact you when
                                        necessary.
                                    </li>
                                    <li>
                                        <strong>Billing for services or payment</strong>
                                        <br />
                                        TeleMed2U, Inc. can use and share your health information to
                                        bill and get payment from health plans or other entities.
                                    </li>
                                    <li>
                                        <strong>Authorization</strong>
                                        <br />
                                        TeleMed2U, Inc. is permitted to use and disclose your health
                                        information upon your written authorization, to the extent
                                        such use or disclosure is consistent with your
                                        authorization. You may revoke an authorization at any time.
                                    </li>
                                    <li>
                                        <strong>Public health and safety issues</strong>
                                        <br />
                                        TeleMed2U, Inc. can share health information about you for
                                        certain situations such as:&nbsp; disease prevention,
                                        product recalls, adverse event reporting, reporting
                                        suspected abuse, neglect, or domestic violence, and
                                        preventing or reducing a serious threat to anyone’s health
                                        or safety. If your provider perceives you may be at risk of
                                        causing serious bodily harm to yourself or another person,
                                        they may contact your emergency contact or emergency
                                        services.
                                    </li>
                                    <li>
                                        <strong>As required by the law</strong>
                                        <br />
                                        TeleMed2U, Inc. will share information about you if state or
                                        federal laws require it.
                                    </li>
                                    <li>
                                        <strong>Organ and tissue donation requests</strong>
                                        <br />
                                        TeleMed2U, Inc. can share health information about you with
                                        organ procurement organizations.
                                    </li>
                                    <li>
                                        <strong>Medical examiner or funeral director</strong>
                                        <br />
                                        TeleMed2U, Inc. can share health information with a coroner,
                                        medical examiner, or funeral director when an individual
                                        dies for purposes such as identification, determining the
                                        cause of death and fulfilling duties relating to decedents.
                                    </li>
                                    <li>
                                        <strong>
                                            Workers’ compensation
                                            <br />
                                        </strong>
                                        TeleMed2U, Inc. may disclose your PHI to the extent
                                        necessary to comply with workers’ compensation programs and
                                        other similar programs relating to work-related illnesses or
                                        injuries.
                                    </li>
                                </ul>
                                <ul role="list">
                                    <li>
                                        <strong>Government requests</strong>
                                        <br />
                                        TeleMed2U, Inc. can use or share health information about
                                        you:
                                    </li>
                                </ul>
                                <ul role="list">
                                    <li>With a law enforcement official</li>
                                    <li>
                                        With health oversight agencies or for authorized activities
                                        such as audits, investigations, inspections, licensing and
                                        disciplinary actions relating to the healthcare system or
                                        government benefit programs.
                                    </li>
                                    <li>
                                        For special government functions such as military, national
                                        security, presidential protective services, or intelligence
                                        and national security purposes.
                                        <br />
                                    </li>
                                </ul>
                                <ul role="list">
                                    <li>
                                        <strong>Lawsuits and legal actions</strong>
                                        <br />
                                        TeleMed2U may share PHI about you in response to a court or
                                        administrative order, or in response to a subpoena.
                                    </li>
                                    <li>
                                        <strong>
                                            Notification of breach
                                            <br />
                                        </strong>
                                        TeleMed2U, Inc. will let you know promptly if a breach
                                        occurs that may have compromised the privacy or security of
                                        your information.&nbsp; &nbsp;
                                    </li>
                                </ul>
                                <h3>Changes to the Terms of this Notice</h3>
                                <p>
                                    TeleMed2U, Inc. can change the terms of this notice, and the
                                    changes will apply to all information we have about you. The new
                                    notice will be available upon written request and on our
                                    website.
                                    <br />
                                </p>
                            </div>
                        </TabPanel>
                        <TabPanel
                            value={currentTab}
                            index={5}
                            tabContentRef={(ref) => (tabContentRefs.current[5] = ref)}
                            setIsScroll={setIsScroll}>
                            <h3 style={{ textAlign: 'center' }}>Release of Information</h3>
                            <p>
                                I, the patient or his/her/them parent/legal representative, hereby
                                authorize TeleMed2U to release/obtain information regarding my
                                medical history, illness or injury, consultation, prescriptions,
                                treatment, diagnosis, or prognosis, including X-rays, correspondence
                                and/or medical records including those from my other health care by
                                means of mail, fax, or other electronic methods.
                                <br />
                                <br />I understand that this authorization extends to all or any
                                part of the records, which may include treatment for physical and
                                mental illness (except for psychotherapy notes), chemical or alcohol
                                dependency, communicable disease such as Human Immunodeficiency
                                Virus (“HIV”) and Acquired Immune Deficiency Syndrome (“AIDS”) test
                                results or diagnoses. I understand that my records may be protected
                                by the Federal Rules for Privacy of Individually Identifiable Health
                                Information (Title 45 of the Code of Federal Regulations, Parts 160
                                and 164), the Federal Rules for Confidentiality of Alcohol and Drug
                                Abuse Patient Records (Title 42 of the Code of Federal Regulations,
                                Chapter 1, Part 2), and/or state laws, and cannot be disclosed
                                without my written consent at any time. I understand this consent is
                                subject to revocation at any time, except to the extent that action
                                has been taken in reliance on it, and that in any event this consent
                                expires automatically as indicated below. I understand that this
                                authorization is voluntary, and I may refuse to sign this
                                authorization. I further understand that my health care and payment
                                of my health care will not be affected if I do not sign this form.
                                <br />
                                <br />
                                Permissions for further use or disclosure of this medical
                                information is not granted unless another authorization is obtained
                                from me or unless such disclosure is specifically required or
                                permitted by law.
                                <br />
                                <br />A photocopy of facsimile of this authorization shall be
                                considered as effective and valid as the original.
                                <br />‍<br />A have been advised of my right to receive a copy of
                                this authorization.
                                <br />‍<br />I hereby release TeleMed2U from all legal
                                responsibilities or liability that may arise from disclosure of my
                                medical records in reliance of this Authorization.
                                <br />‍<br />
                                <br />‍<br />
                                <br />‍<br />
                                <br />‍<br />
                                <br />‍<br />
                                <br />‍<br />
                                <br />‍<br />
                                <br />‍<br />
                            </p>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                        </TabPanel>
                    </Grid>
                </div>
                <Divider orientation="horizontal" className="modal-divider" />
                <div className="terms-modal">
                    <CheckboxOption
                        option={{
                            label: '',
                            value: true,
                        }}
                        disabled={!isCheckboxEnabled}
                        customStyle={{
                            opacity: isCheckboxEnabled || values?.termsSign ? 1 : 0.5,
                            marginTop: 2,
                        }}
                        checked={values.termsAgree}
                        onChange={(event) => {
                            setValues('termsAgree', event.target.checked)
                        }}
                    />
                    <div className="modal-footer-content">
                        <span
                            className="agreement"
                            style={{ opacity: isCheckboxEnabled || sign ? 1 : 0.5 }}>
                            I agree to the TeleMed2U{' '}
                            <span onClick={() => setCurrentTab(0)}>Terms & Conditions</span>,{' '}
                            <span onClick={() => setCurrentTab(1)}>
                                Informed Consent for Medical Services
                            </span>
                            , <span onClick={() => setCurrentTab(2)}>Telehealth Consent</span>,{' '}
                            <span onClick={() => setCurrentTab(3)}>Privacy Policy</span> and{' '}
                            <span onClick={() => setCurrentTab(4)}>
                                Notice of Privacy Practices
                            </span>
                            , and understand all of these can be accessed at any time on{' '}
                            <span onClick={() => goToPage('https://www.telemed2u.com')}>
                                telemed2u.com
                            </span>
                            . I have read and give my permission for the{' '}
                            <span onClick={() => setCurrentTab(5)}>Release of Information .</span>
                        </span>
                        <TextField
                            // onChange={handleChange}
                            style={{ opacity: isCheckboxEnabled || values?.termsSign ? 1 : 0.5 }}
                            disabled={!values.termsAgree}
                            required
                            name="sign"
                            value={sign}
                            // {...params}
                            placeholder="Sign name here"
                            variant="outlined"
                            onChange={(e) => setSign(e.target.value)}
                        />
                        {values.termsAgree ? (
                            <Button
                                style={{
                                    backgroundColor: !sign ? 'rgba(85, 113, 198, 0.75)' : '#5571c6',
                                    color: '#fff',
                                }}
                                onClick={handleSaveSign}
                                disabled={!sign}>
                                Save
                            </Button>
                        ) : (
                            <Button style={{ backgroundColor: '#5571c6' }} onClick={onModalClose}>
                                Close
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default IntakeModalForm
