import React, { useState } from 'react'
import doctor_img from '../../../assests/images/PatientApoointment/doctorImg.png'
import { Typography } from '@material-ui/core'
import BookingWith from './StepperComponents/FindYourCare/BookingWith'
import LookingFor from './StepperComponents/FindYourCare/LookingFor'
import LocatedTime from './StepperComponents/FindYourCare/LocatedTime'
import SelectATime from './StepperComponents/SelectATime'
import SelectAProvider from './StepperComponents/SelectAProvider/SelectAProvider'
import ReasonForVisit from './StepperComponents/ReasonForVisit'
import NewPatientForm from './StepperComponents/NewPatientForm/NewPatientForm'
import ReviewAppointment from './StepperComponents/ReviewAppointment'

import Booking from './Booking'
import BookingButtons from './BookingButtons'

import { INIT_VALUES as NEW_PATIENT_FORM_INIT_VALUES } from './StepperComponents/NewPatientForm/constants'
import { validation as newPatientFormValidation } from './StepperComponents/NewPatientForm/validation'
import { INIT_VALUES as INTAKE_FORM_INIT_VALUES } from './StepperComponents/IntakeForm/constants'
import { validation as intakeFormValidation } from './StepperComponents/IntakeForm/validation'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import IntakeForm from './StepperComponents/IntakeForm/IntakeForm'
import Routes from '../../../constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { stateList } from '../../../containers/AppointmentBooking/constant'
import { TIMEZONES } from '../../../containers/CalendarScheduler/constant'
import { TIMEZONE_OPTONS } from '../../../constants/timezones'
import { useNotification } from '../MaterialUi/Notification'
import { patientTimeZone } from '../../../containers/AppointmentList/utils'
import { addIntakeForm, newBookAppointment } from '../../../services/Appointments/appointments'
import AppointmentLoadingPage from './StepperComponents/AppointmentLoadingPage'
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { config } from '../../../constants'
import moment from 'moment'
import { changeHeaderTab } from '../../../store/actions/header'
import { reversedHeaderConstants } from '../../Header/constants'
import { Button, Dialog, Box } from '@mui/material'
import {useMediaQuery} from '@material-ui/core'

const removeEmptyObjects = (formData) => {
    return formData.filter((obj) => {
        // Check if any key is not empty in the current object
        const hasNonEmptyKey = Object.values(obj).some((value) => value !== '')
        // If any key is not empty, keep the object in the array
        return hasNonEmptyKey
    })
}
function StepperComponents() {
    const history = useHistory()
    const dispatch = useDispatch()
    const userData = useSelector((state) => state?.login?.userData)
    const notification = useNotification()
    const [isLoading, setIsLoading] = useState(false)
    const [steps, setSteps] = React.useState(0)
    const stepperRef = React.useRef(null)
    // const [isFirstTime, setIsFirstTime] = React.useState(true)

    const [appointmentDetails, setAppointmentDetails] = React.useState({})
    const [reviewAppointmentLoader, setReviewAppointmentLoader] = React.useState(true)

    const [intakeFormTouchedFields, setIntakeFormTouchedFields] = React.useState({})

    const [findYourCareValues, setFindYourCareValues] = React.useState({
        bookingWith: '',
        locatedTime: '',
        lookingFor: '',
    })

    const [reasonForVisit, setReasonForVisit] = React.useState('')
    const [requireTranslator, setRequireTranslator] = React.useState(userData?.require_translator)
    const [medications, setMedications] = React.useState([
        {
            name: '',
            strength: '',
            directions: '',
        },
        {
            name: '',
            strength: '',
            directions: '',
        },
        {
            name: '',
            strength: '',
            directions: '',
        },
    ])

    const [selectATimeValues, setSelectSelectATimeValues] = React.useState({
        morning: false,
        afternoon: false,
        evening: false,
        timezone: '',
        date: moment().add(1, 'days'),
    })

    const [selctedProviderDetails, setSelectedProviderDetails] = React.useState({})
    const [selectedTime, setSelectedTime] = React.useState({})
    const [returnedErrorMessage, setReturnedErrorMessage] = useState(false)
    const [providerVisitType, setProviderVisitType] = React.useState({})
    const [sign, setSign] = React.useState('')
    const [isCheckboxEnabled, setIsCheckboxEnabled] = React.useState(false)

    const [intakeFormWarning, setIntakeFormWarning] = useState(false) 
    const [isWaningDismissed, setIsWaningDismissed] = useState(false)

    const isTablet = useMediaQuery((theme) =>
        theme.breakpoints.down('lg'),
    );

    const intakeFormObjectToArrayConvert = (objectValues = null) => {
        if (!objectValues) return []
        const returnArray = []
        Object.keys(objectValues).forEach((keyItem) => {
            if (objectValues[keyItem])
                returnArray.push(`${keyItem?.charAt(0)?.toUpperCase()}${keyItem.substring(1)}`)
        })
        return returnArray
    }

    const intakeFormFormik = useFormik({
        initialValues: INTAKE_FORM_INIT_VALUES,
        // onSubmit: handleSubmitIntakeForm,
        validationSchema: intakeFormValidation,
    })

    const handleSubmitIntakeForm = (callback) => {
        setReviewAppointmentLoader(true)

        const values = intakeFormFormik?.values

        const intakeFormPayload = {
            regular_care: values?.regularConsultation,
            regular_consultation_place: values?.regularConsultationPlace,
            using_insurance: values?.insurance,
            legal_issues: values?.legalIssues,
            harm_others: intakeFormObjectToArrayConvert(values.harmingOthers),
            suicide_ideation: intakeFormObjectToArrayConvert(values.suicidal),
            alcohol_abuse: intakeFormObjectToArrayConvert(values.alcoholAbuse),
            substance_abuse: intakeFormObjectToArrayConvert(values.substanceAbuse),
            term_signed: values?.termsSign,
            timezone: userData?.timezone || 'us/pacific',
            medications: removeEmptyObjects(medications),
        }

        addIntakeForm(intakeFormPayload)
            ?.then((res) => {
                callback()
            })
            ?.catch(() => {
                notification("couldn't add intake form!", 'error')
            })
            ?.finally(() => setReviewAppointmentLoader(false))
    }

    const handleFindYourCareValues = (value, fieldName) => {
        setFindYourCareValues((state) => {
            return {
                ...state,
                [fieldName]: value,
            }
        })
    }

    const handleSelectATimeValues = (value, fieldName) => {
        setSelectSelectATimeValues((state) => {
            return {
                ...state,
                [fieldName]: value,
            }
        })
    }

    const handleChangeHeaderTab = () => {
        dispatch(changeHeaderTab(reversedHeaderConstants[Routes.DASHBOARD.split('/')[1]]))
    }

    const flattenAppointmentDetailsResponse = (data) => {
        if (!data) return
        return {
            providerName: `${selctedProviderDetails?.first_name} ${selctedProviderDetails?.last_name}`,
            speciality: selctedProviderDetails?.specialities[0]?.label,
            date: data?.date ? moment(data?.date).format('MMMM Do, YYYY') : 'N/A',
            startTime: data?.start
                ? patientTimeZone(data?.start, userData?.timezone).format('h.mm A')
                : 'N/A',
            endTime: data?.end
                ? patientTimeZone(data?.end, userData?.timezone).format('h.mm A')
                : 'N/A',
        }
    }

    const handleBookAppointment = async (callback) => {
        setReviewAppointmentLoader(true)

        const bookAppointmentPayload = {
            date: patientTimeZone(selectedTime?.start_time)?.format('YYYY-MM-DD'),
            end_time: patientTimeZone(selectedTime?.end_time)?.format('HH:mm'),
            start_time: patientTimeZone(selectedTime?.start_time)?.format('HH:mm'),
            appointment_type: 'Telehealth Visit',
            reason_for_visit: reasonForVisit,
            internal_note: '',
            specialty_id: providerVisitType?.specialty_id,
            location_id: config.dev.appointmentLocationId,
            duration: providerVisitType?.duration,
            visit_type_id: providerVisitType?.id,
            patient_id: userData?.id,
            practitioner_id: selctedProviderDetails?.id,
            supervising_provider: selctedProviderDetails?.supervising_physician,
            is_require_translator: requireTranslator,
            appointment_status: 'Appt_Not_Start',
            is_reoccurring_appointment: false,
            days: [],
            end_date: '',
            unit: '',
            once_every: '',
            end_type: '',
            timezone: userData?.timezone,
            patientName: `${userData?.first_name} ${userData?.last_name}`,
            practitioner_first_name: selctedProviderDetails?.first_name,
            practitioner_last_name: selctedProviderDetails?.last_name,
            populate: false,
            reoccuring_created: false,
            color_tag: providerVisitType?.color_tag,
            clinical_note_id: '',
            notes: [],
            note: '',
            noteView: 'no-view',
            start: selectedTime?.start_time,
            end: selectedTime?.end_time,
            appointment_for: 'patient',
            participants: [
                {
                    status: 'accepted',
                    role: 'Patient',
                    participant_id: userData?.id,
                },
            ],
            status: 'pending',
        }
        try {
            setIsLoading(true)
            const appointMentCreateResult = await newBookAppointment(bookAppointmentPayload)
            setAppointmentDetails(
                flattenAppointmentDetailsResponse(appointMentCreateResult.data.data),
            )
            if (
                !appointMentCreateResult?.data?.status &&
                appointMentCreateResult?.data?.error === 'Appointment slot is already booked!'
            ) {
                notification(appointMentCreateResult?.data?.error)
                setReturnedErrorMessage(true)
                setSteps(4)
                return
            }
            notification('Appoinment is booked succesfully!', 'success')
            if (findYourCareValues.bookingWith === 'yes') handleSubmitIntakeForm(callback)
            else callback()
        } catch (e) {
            notification('Something went wrong!', 'error')
        } finally {
            setIsLoading(false)
            setReviewAppointmentLoader(false)
        }
    }

    const scrollTop = () => {
        stepperRef.current.parentElement.parentElement.scrollTop = 0
    }

    const handleNextStep = () => {
        if (steps === 6) {
            history.push(Routes.DASHBOARD)
            handleChangeHeaderTab()
        } else {
            scrollTop()
            if( steps === 1 && (intakeFormFormik?.values?.harmingOthers?.present || intakeFormFormik?.values?.suicidal?.present) && !isWaningDismissed){
                setIntakeFormWarning(true)
                setIsWaningDismissed(true)
            } else {
                if (steps === 5.5) {
                    handleBookAppointment(() => {
                        setSteps((state) => {
                            if (findYourCareValues.bookingWith !== 'yes' && state === 0) return 2 //condition for intake form step
                            if (state === 2) return 2.5 //moving from 1st substep of find your care step
                            if (state === 2.5) return 3 //moving from 2nd substep of find your care step
                            return Math.floor(state + 1)
                        })
                    })
                } else {
                    setSteps((state) => {
                        if (findYourCareValues.bookingWith !== 'yes' && state === 0) return 2 //condition for intake form step
                        if (state === 5) return 5.5
                        if (state === 2) return 2.5 //moving from 1st substep of find your care step
                        if (state === 2.5) return 3 //moving from 2nd substep of find your care step
                        return state + 1
                    })
                }
            }
        }
    }
    const handlePreviousStep = () => {
        if (steps === 0) {
            history.push(Routes.DASHBOARD)
            handleChangeHeaderTab()
        } else {
            scrollTop()
            setSteps((state) => {
                if (findYourCareValues.bookingWith !== 'yes' && state === 2) return 0
                if (state === 3) return 2.5 //back to 2nd substep of find your care step
                if (state === 2.5) return 2 //back to 1st substep of find your care step
                if (state === 5.5) return 5
                return state - 1
            })
        }
    }

    const handleSubmitNewPatient = () => {
        return ''
    }

    const newPatientFormik = useFormik({
        initialValues: NEW_PATIENT_FORM_INIT_VALUES,
        onSubmit: handleSubmitNewPatient,
        validationSchema: newPatientFormValidation,
    })

    const intakeFormOnChange = (fieldName, value) => {
        intakeFormFormik.setFieldValue(fieldName, value)
        setIntakeFormTouchedFields((state) => ({
            ...state,
            [fieldName]: true,
        }))
    }

    const stepperContinueConditions = (currentStep) => {
        switch (currentStep) {
            case 0: {
                return findYourCareValues.bookingWith === ''
            }
            case 2: {
                return findYourCareValues.lookingFor === ''
            }
            case 2.5: {
                return !findYourCareValues?.locatedTime || findYourCareValues.locatedTime === ''
            }
            case 3: {
                return !Object.values(selectATimeValues).includes(true)
            }
            case 4: {
                return (
                    Object.keys(selctedProviderDetails).length === 0 ||
                    Object.keys(selectedTime).length === 0
                )
            }
            case 5: {
                return reasonForVisit === ''
            }
            case 1: {
                return (
                    Object.keys(intakeFormFormik.errors).length !== 0 ||
                    Object.keys(intakeFormTouchedFields).length === 0
                )
            }
            default:
                return false
        }
    }

    const providerDetails = (date = {}, ProviderDetail = {}) => {
        setSelectedTime(date)
        setSelectedProviderDetails(ProviderDetail)
    }

    React.useEffect(() => {
        const userState = stateList?.find(
                (stateListItem) => stateListItem?.state === userData?.state,
            )?.stateLabel,
            userTimezone =
                TIMEZONE_OPTONS?.find((timezoneList) => timezoneList.key === userData?.timezone)
                    ?.abbr || 'PST'

        handleFindYourCareValues(userState, 'locatedTime')

        handleSelectATimeValues(userTimezone, 'timezone')
    }, [userData])

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className='outer_stepper_holder' ref={stepperRef}>
            <div>
                <Booking steps={steps} isFirstTime={findYourCareValues.bookingWith === 'yes'} />
            </div>
            {isLoading ? (
                <AppointmentLoadingPage />
            ) : (
                <>
                    <Box className="middle_portion" sx={{ paddingBottom: {sm: '60px', xl: '10px'}, minHeight: {sm: 'auto', xl: '575px'}}}>
                        <div className="doctor_care">
                            <img src={doctor_img} alt="doc-img" style={{ width: isTablet ? '320px' : 'auto',  }}/>

                            <span
                                className="image_text"
                                style={{
                                    top:  isTablet ? '290px' : '356px',
                                    left:  isTablet ? '20px' : '50px',
                                }}>
                                <span
                                    style={{ alignSelf: 'center', fontSize: 10, color: '#FFFFFF' }}>
                                    Image by &nbsp;
                                    <a
                                        target="_blank"
                                        style={{ cursor: 'pointer', color: '#FFFFFF' }}
                                        href="https://www.freepik.com/free-vector/online-doctor-with-white-coat_7774236.htm#&position=0&from_view=search&track=ais&uuid=ecae6f53-8d78-498d-8803-703a8183a4e1"
                                        rel="noopener noreferrer">
                                        Freepik
                                    </a>
                                </span>
                                <Typography>
                                    We are here to provide you with quality care.
                                </Typography>
                                <span style={{ display: 'flex', gap: 4 }}>
                                    <Typography>If you need assistance call</Typography>
                                    <Typography className="number">
                                        {userData?.practice?.patient_support_number ||
                                            '(562) 268-0955'}
                                    </Typography>
                                </span>
                            </span>
                        </div>
                        {steps === 0 && (
                            // <AppointmentLoadingPage />
                            <BookingWith
                                name="bookingWith"
                                value={findYourCareValues.bookingWith}
                                setValue={handleFindYourCareValues}
                            />
                        )}
                        {steps === 1 && (
                            <IntakeForm
                                values={intakeFormFormik.values}
                                setValues={intakeFormOnChange}
                                sign={sign}
                                setSign={setSign}
                                isCheckboxEnabled={isCheckboxEnabled}
                                setIsCheckboxEnabled={setIsCheckboxEnabled}
                                errors={intakeFormFormik.errors}
                                touched={intakeFormTouchedFields}
                            />
                        )}
                        {steps === 2 && (
                            <LookingFor
                                name="lookingFor"
                                value={findYourCareValues.lookingFor}
                                setValue={handleFindYourCareValues}
                            />
                        )}
                        {steps === 2.5 && (
                            <LocatedTime
                                name="locatedTime"
                                value={findYourCareValues.locatedTime}
                                setValue={handleFindYourCareValues}
                                userState={userData?.state}
                            />
                        )}
                        {steps === 3 && (
                            <SelectATime
                                value={selectATimeValues}
                                setValue={handleSelectATimeValues}
                            />
                        )}
                        {steps === 4 && (
                            <SelectAProvider
                                findYourCareValues={findYourCareValues}
                                selectATimeValues={selectATimeValues}
                                providerDetails={providerDetails}
                                setProviderVisitType={setProviderVisitType}
                                returnedErrorMessage={returnedErrorMessage}
                                setReturnedErrorMessage={setReturnedErrorMessage}
                                goToRequiredStep={setSteps}
                                clinicalPracticeNumber={userData?.practice?.patient_support_number}
                                handleSelectATimeValues={handleSelectATimeValues}
                            />
                        )}
                        {(steps === 5 || steps === 5.5) && (
                            <ReasonForVisit
                                value={reasonForVisit}
                                setValue={setReasonForVisit}
                                selctedProviderDetails={selctedProviderDetails}
                                selectedTime={selectedTime}
                                medications={medications}
                                setMedications={setMedications}
                                bookingWith={findYourCareValues.bookingWith}
                                steps={steps}
                                setSteps={setSteps}
                                handleNextStep={handleNextStep}
                                requireTranslator={requireTranslator}
                                setRequireTranslator={setRequireTranslator}
                            />
                        )}
                        {/* {steps === 4 && <NewPatientForm formik={newPatientFormik} />} */}

                        {steps === 6 && (
                            <ReviewAppointment
                                appointmentDetails={appointmentDetails}
                                reviewAppointmentLoader={reviewAppointmentLoader}
                                selctedProviderDetails={selctedProviderDetails}
                            />
                        )}

                        <div className='common_information_holder d-lg-none'>
                            <h4 className='heading'>We are here to provide you with quality care. If you need assistance call</h4>
                            <a href="tel:+1-562-268-0955" className='content'>(562) 268-0955.</a>
                        </div>
                    </Box>

                    <Box
                        className='wizard_btn_holder lll'
                        sx={{ 
                            position: {sm: 'fixed', xl: 'static'}, 
                            left: { md: '50%', xl: 'auto' },
                            transform: { md: 'translate(-50%)', xl: 'none' },
                            bottom: 0, 
                            width: 'calc(100% - 40px)',
                            margin: 0,
                            padding: '15px 20px 10px 20px',
                            backgroundColor: 'white',  
                            zIndex: 100,  
                        }}
                    >
                        <BookingButtons
                            steps={steps}
                            handleNextStep={handleNextStep}
                            handlePreviousStep={handlePreviousStep}
                            stepperContinueConditions={stepperContinueConditions}
                        />
                    </Box>
                </>
            )}

            <Dialog 
                open={intakeFormWarning} 
                onClose={() => setIntakeFormWarning(false)}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px'}}>
                    <WarningAmberIcon style={{fontSize: '60px', color: '#E81A1A'}}/>
                    <Typography style={{fontSize: '20px', color: '#000', margin: '25px 0px'}}>
                        If you are in crisis and need help, please call 911.
                    </Typography>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => setIntakeFormWarning(false)}
                        style={{ background: '#5571C6', width: '100%' }}>
                        Dismiss
                    </Button>
                </div>
            </Dialog>
        </div>
    )
}

export default StepperComponents